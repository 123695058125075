@import 'assets/scss/variables';
@import 'assets/scss/utils';

.card {
  gap: 12px;
  padding: 8px;
  display: flex;
  border-radius: 16px;
  transition: border 150ms ease-out;
  border: 1px solid var(--border-color);

  @media (max-width: $screen-md-max) {
    flex-direction: column;
  }

  &__img-wrapper {
    height: 194px;
    min-width: 304px;
    border-radius: 12px;
    background-color: var(--banner-bg);

    @media (max-width: $screen-md-max) {
      width: 100%;
    }
  }

  &__content {
    gap: 16px;
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;

    div {
      height: 18px;
      border-radius: 4px;
      background-color: var(--banner-bg);
    }

    &__category {
      width: 48px;
    }

    &__title {
      width: 96px;
    }

    &__badge {
      width: 192px;
    }

    &__progress {
      width: 100%;
      height: 12px;
      margin-top: 16px;
    }

    &__button {
      width: 160px;
      height: 32px;
    }
  }
}
