@import 'assets/scss/mixins';
@import 'assets/scss/utils';

.container {
  gap: 16px;
  display: flex;
  padding: 16px;
  border-radius: 12px;
  align-items: center;
  border: 1px solid var(--border-color);

  &__light {
    background-color: white;
  }

  .course {
    gap: 8px;
    display: flex;
    flex-direction: column;
    color: var(--content-color);
    > div:last-child {
      font-family: $Poppins;
      color: var(--content-color);
    }
  }

  .thumb {
    height: 92px;
    border-radius: 12px;
    object-fit: cover;
    &--sm {
      height: 60px;
      width: 92px;
    }
    &--lg {
      height: 92px;
      width: 146px;
    }
  }
}
