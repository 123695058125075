@import 'assets/scss/general';

.resources {
  &--theatre-mode {
    display: grid;
    grid-template-columns: 4fr 2fr;
    gap: 32px;
    width: 100%;
    flex: 1 2;
  }
}
