@import 'assets/scss/general';
@import 'assets/scss/utils';
@import 'assets/scss/variables';

.about-container {
  gap: 40px;
  display: flex;
  flex-direction: column;

  .section {
    gap: 16px;
    display: flex;
    max-width: 744px;
    flex-direction: column;

    .requirements {
      padding-left: 8px;
    }

    .agenda-bullets {
      display: flex;
      flex-wrap: wrap;
      padding-left: 8px;

      div {
        width: 50%;
      }

      .bullet {
        gap: 12px;
        display: flex;
        margin-bottom: 12px;

        @media (max-width: $screen-md-max) {
          width: 100%;
        }
      }

      @media (max-width: $screen-md-max) {
        flex-direction: column;
      }
    }

    .badge-wrapper {
      gap: 12px;
      display: flex;
      margin-top: 8px;
      align-items: center;

      div {
        border-color: var(--text-primary);
      }
    }
  }
}
