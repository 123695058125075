@import 'assets/scss/variables';
@import 'assets/scss/mixins';
@import 'assets/scss/utils';

.main-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
  @include fontBody2;

  &__input-wrapper {
    display: flex;
    gap: 16px;

    &--disabled {
      color: var(--input-text-disabled) !important;
      svg {
        fill: var(--input-text-disabled) !important;
      }
      .custom-file-upload {
        cursor: default !important;
        border: 1px solid var(--input-border-color-disabled) !important;
      }
      .input-container__guidelines {
        div {
          color: var(--input-text-disabled) !important;
        }
        span {
          color: var(--input-text-disabled) !important;
        }

        &__tips {
          margin-top: auto;
        }
      }
    }

    &--centered {
      justify-content: center;
    }

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }
  }

  .input-container {
    display: flex;
    border-radius: 8px;
    position: relative;
    opacity: 1;
    gap: 16px;
    transition: opacity 350ms;

    .custom-file-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: 1px solid transparent;
      border-radius: 8px;
      background-color: transparent;
      cursor: pointer;
      z-index: 1;

      .instructions {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        svg {
          fill: var(--content-color);
        }
      }

      .file-wrapper {
        .actions {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          background: var(--overlay);
          opacity: 0;
          transition: var(--transition);

          .divider {
            background-color: var(--text-primary);
          }

          @media (max-width: #{$screen-xl-max}) {
            padding-top: 0;
            opacity: 1;
          }

          svg {
            fill: var(--text-primary);
          }
        }

        &:hover {
          .actions {
            padding-top: 0;
            opacity: 1;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
          }
        }

        .image-comp {
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 8px;

          &--sm {
            width: 192px;
            height: 192px;
          }

          &--md {
            width: 288px;
            height: 192px;
          }

          &--square {
            width: 192px;
          }
        }

        video {
          border-radius: 12px;
        }
      }

      &--empty {
        border: 1px solid var(--input-border-color);

        &:hover {
          border: 1px solid var(--input-border-color-hover);
        }
        &:active {
          border: 1px solid var(--input-border-color-active);
          svg {
            fill: var(--text-primary);
          }
        }
      }

      &--error {
        border: 1px solid $error40;

        &:hover {
          border: 1px solid var(--input-border-color-hover);
        }
        &:active {
          border: 1px solid var(--input-border-color-active);
          svg {
            fill: var(--text-primary);
          }
        }
      }

      &--sm {
        width: 64px;
        height: 64px;
      }

      &--md {
        width: 288px;
        height: 192px;
      }
    }

    input[type='file'] {
      display: none;
    }

    &.square {
      width: 192px;
      height: 192px;
      border-radius: 8px;
    }

    &__guidelines {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &__wrapper {
        display: flex;
        flex-direction: column;

        .tip {
          margin-top: auto;
        }
      }
    }
  }
}
