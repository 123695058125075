@import "assets/scss/utils";

.wrapper {
  display: flex;
  justify-content: center;
  padding: 48px 0;
  background: var(--app-bg);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    width: $screen-lg-max;

    @media (max-width: $screen-lg-max) {
      width: 100%;
    }
  }
}
