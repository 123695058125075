@import 'assets/scss/variables';

.c-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
}

.c-wrapper-padding {
  padding: 60px 0;
}

.c-loader {
  margin: 0 auto;
  position: relative;

  &--sm {
    width: 20px;
    height: 20px;
  }

  &--md {
    width: 40px;
    height: 40px;
  }

  &--lg {
    width: 80px;
    height: 80px;
  }

  &--xl {
    width: 140px;
    height: 140px;
  }
}

.c-loader-text {
  color: var(--text-primary);
  font-family: 'Poppins SemiBold', sans-serif;
  margin-top: 30px;
  text-align: center;
  display: block;
}

.c-loader .c-loader-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.c-loader .c-loader-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 10%;
  height: 10%;
  background-color: $purple60;
  border-radius: 100%;
  -webkit-animation: c-loader-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: c-loader-circleFadeDelay 1.2s infinite ease-in-out both;
}

.neutral::before {
  background-color: var(--text-primary) !important;
}

.primary::before {
  background-color: $purple60 !important;
}

.success::before {
  background-color: $success40 !important;
}

.error::before {
  background-color: $error40 !important;
}

.white::before {
  background-color: $white10 !important;
}

.c-loader .c-loader-circle2 {
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.c-loader .c-loader-circle3 {
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.c-loader .c-loader-circle4 {
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.c-loader .c-loader-circle5 {
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.c-loader .c-loader-circle6 {
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.c-loader .c-loader-circle7 {
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.c-loader .c-loader-circle8 {
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.c-loader .c-loader-circle9 {
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.c-loader .c-loader-circle10 {
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.c-loader .c-loader-circle11 {
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.c-loader .c-loader-circle12 {
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.c-loader .c-loader-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.c-loader .c-loader-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.c-loader .c-loader-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.c-loader .c-loader-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.c-loader .c-loader-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.c-loader .c-loader-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.c-loader .c-loader-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.c-loader .c-loader-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.c-loader .c-loader-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.c-loader .c-loader-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.c-loader .c-loader-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes c-loader-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes c-loader-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
