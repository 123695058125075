@import 'assets/scss/variables';
@import 'assets/scss/utils';

.card {
  gap: 16px;
  padding: 8px;
  height: 365px;
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  transition: border 150ms ease-out;
  border: 1px solid var(--border-color);
  cursor: pointer;

  &:hover {
    box-shadow: var(--secondary-shadow);
  }

  &__img-wrapper {
    display: flex;
    min-height: 127px;
    border-radius: 12px;
    align-items: center;
    justify-content: center;

    .cover {
      width: 100%;
      height: 100%;
      border-radius: 12px;

      &--empty {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--banner-bg);

        svg {
          fill: var(--banner-svg);
          width: 35%;
          height: 35%;
        }
      }
    }
  }

  &__content {
    gap: 16px;
    width: 100%;
    padding: 8px;
    height: 100%;
    display: flex;
    margin-top: -70px;
    flex-direction: column;
    justify-content: space-between;

    .info {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;

      svg {
        fill: var(--text-primary);
      }

      .name {
        gap: 4px;
        display: flex;
        align-items: center;
      }
    }

    button {
      width: 100%;
    }
  }
}
