@import 'assets/scss/utils';
@import 'assets/scss/variables';

.popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .content {
    width: 384px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;

    @media (max-width: $screen-sm-max) {
      width: 100%;
    }

  }

  .btns-container {
    gap: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  img {
    width: 288px;
    height: auto;
    border-radius: 12px;
  }
}
