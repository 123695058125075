@import 'assets/scss/utils';
@import 'assets/scss/variables';

.certificate {
  &__list {
    display: flex;
    width: 100%;

    &--center {
      justify-content: center;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &__thumbnail {
      max-width: 512px;
      @media (max-width: $screen-md-max) {
        max-width: 100%;
      }
    }

    &__info-box {
      max-width: 540px;
    }
  }

  &__btns-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
