// Colors
$white10: #ffffff;
$white20: #f6f6f6;
$white30: #f0f0f0;
$white40: #e5e5e5;
$white50: #dddddd;
$white60: #bdbdbd;
$white70: #b0b0b0;
$white80: #a2a2a2;
$white90: #959595;
$white100: #888888;

$black10: #8F8F8F;
$black20: #7B7B7B;
$black30: #6E6E6E;
$black40: #616161;
$black50: #545454;
$black60: #474747;
$black70: #393939;
$black80: #2C2C2C;
$black90: #1F1F1F;
$black100: #121212;

$purple10: #eed9ff;
$purple20: #deb5ff;
$purple30: #cf93ff;
$purple40: #ba64ff;
$purple50: #a029ff;
$purple60: #8e00ff;
$purple70: #7400d0;
$purple80: #5a00a1;
$purple90: #45007c;
$purple100: #2e0053;

$darkBlue10: #606af8;
$darkBlue20: #4851d8;
$darkBlue30: #363eb0;
$darkBlue40: #313893;
$darkBlue50: #282d75;
$darkBlue60: #202460;
$darkBlue70: #1b1e4b;
$darkBlue80: #161839;
$darkBlue90: #0f1029;
$darkBlue100: #080918;

$lightBlue10: #fbfcff;
$lightBlue20: #f9faff;
$lightBlue30: #f5f7ff;
$lightBlue40: #edefff;
$lightBlue50: #e5e8ff;
$lightBlue60: #dcdfff;
$lightBlue70: #ced2ff;
$lightBlue80: #bcc1ff;
$lightBlue90: #9fa6ff;
$lightBlue100: #858dff;

$success10: #b2faca;
$success20: #6ae794;
$success30: #29bc63;
$success40: #0da048;
$success50: #037a33;

$warning10: #ffeec2;
$warning20: #ffdc84;
$warning30: #ffcc47;
$warning40: #ffb800;
$warning50: #d79b00;

$error10: #ffbece;
$error20: #ff86a3;
$error30: #ff547d;
$error40: #ea1548;
$error50: #a40128;

// font-sizes
$body4: 10px;
$body3: 12px;
$body2: 14px;
$body1: 16px;
$h5: 18px;
$h4: 24px;
$h3: 32px;
$h2: 48px;
$h1: 64px;

// line-heights
$lhBody4: 14.4px;
$lhBody3: 16.32px;
$lhBody2: 20.44px;
$lhBody1: 24px;
$lhH5: 24.48px;
$lhH4: 31.68px;
$lhH3: 48px;
$lhH2: 64.32px;
$lhH1: 80px;

// font families
$PoppinsLight: 'Poppins Light';
$Poppins: 'Poppins Regular';
$PoppinsMedium: 'Poppins Medium';
$PoppinsSemibold: 'Poppins Semibold';
$PoppinsBold: 'Poppins Bold';

// Maximum width of sections
$maxSectionWidth: 1440px;

// Small smartphones
$screen-xs-max: 375px;

// Small tablets and large smartphones (landscape view)
$screen-sm-max: 576px;

// Small tablets (portrait view)
$screen-md-max: 768px;

// Tablets and small desktops
$screen-lg-max: 992px;

// Large tablets and desktops
$screen-xl-max: 1230px;

// Extra large desktops
$screen-xxl-max: 1440px;
