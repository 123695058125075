@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.category-field {
  width: 100%;
  height: 48px;
  display: flex;
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  padding: 16px 16px 16px 8px;
  justify-content: space-between;
  background-color: var(--secondary-bg);

  &__name {
    img {
      width: 24px;
      height: 24px;
    }

    &__arrow {
      &--down {
        transform: rotate(180deg);
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__btns-container {
    gap: 24px !important;

    &__btn {
      width: 24px !important;
      height: 24px !important;

      &--disabled {
        svg {
          fill: var(--disabled-btn-color) !important;
        }
      }
    }

    @media (max-width: $screen-sm-max) {
      gap: 16px !important;
    }
  }
}

.subcategory-field {
  cursor: initial;
  margin-top: 8px;
  padding-left: 64px;
  border: 1px solid var(--border-color);
  background-color: var(--secondary-bg);
}

[data-theme='light'] {
  --disabled-btn-color: #{$white100};
}

[data-theme='dark'] {
  --disabled-btn-color: #{$black10};
}
