@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  .details {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    flex: 1;
    overflow-wrap: anywhere;

    > div:first-child {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex: 1;
    }

    &__title {
      color: var(--text-primary);
      @include fontBody3;
      font-family: $PoppinsSemibold, sans-serif;

      span {
        color: var(--content-color);
        @include fontBody3;
        font-family: $Poppins, sans-serif;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: var(--content-color);
      @include fontBody2;
      font-family: $Poppins, sans-serif;

      &__row {
        flex-direction: row;
      }

      &__social-item {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--content-color);
        transition: color 150ms ease-out;

        svg {
          fill: var(--content-color);
        }

        &-wrapper {
          display: flex;
          justify-content: space-between;
          gap: 12px;

          button {
            height: unset;
            padding: 0;
          }

          > div {
            display: flex;
            align-items: center;
            gap: 4px;

            > span {
              color: var(--content-color);
              @include fontBody3;
              font-family: $Poppins, sans-serif;
            }
          }
        }

        &:hover {
          color: var(--text-primary);

          svg {
            fill: var(--text-primary);
          }
        }
      }
    }
  }
}
