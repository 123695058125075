@import 'assets/scss/variables';

.icon {
  cursor: pointer;
  fill: var(--text-primary);

  &--sm {
    width: 16px;
    height: 16px;
  }

  &--md {
    width: 20px;
    height: 20px;
  }

  &--lg {
    width: 24px;
    height: 24px;
  }
}
