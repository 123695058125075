@import 'assets/scss/fonts';
@import 'assets/scss/utils';

.breadcrumbs__wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  font-family: $Poppins, sans-serif;
  @include fontBody2;

  @media (max-width: $screen-lg-max) {
    flex-wrap: wrap;
  }
}

.breadcrumb {
  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--content-color-inactive);
    cursor: pointer;
    transition: color 150ms ease-out;

    &--active {
      color: var(--text-primary);
      cursor: default;
    }

    &:hover {
      color: var(--text-primary);
    }

    &__arrow {
      width: 16px;
      height: 16px;
      fill: var(--content-color-inactive);
      transform: rotate(90deg);
      transition: fill 150ms ease-out;
    }
  }
}
