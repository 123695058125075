@import 'assets/scss/variables';
@import 'assets/scss/utils';

.wrapper {
  width: 100%;

  .transactions {
    gap: 4px;
    width: 100%;
    display: flex;
    border-radius: 8px;
    align-items: center;
    color: var(--text-primary);
    font-family: $PoppinsSemibold;
    background-color: var(--btn-neutral-bg);
  }
  .loader-wrapper {
    margin-left: auto;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 8px;
    background-color: var(--btn-neutral-bg);
    font-family: $PoppinsSemibold;
    height: 48px;
    width: 100%;
    color: var(--text-primary);
    cursor: pointer;

    @media (max-width: $screen-lg-max) {
      display: none;
    }

    .icons-container {
      display: flex;
      align-items: center;
      gap: 8px;
      .icon {
        cursor: pointer;
        fill: var(--text-primary);
        transition: rotate 150ms;
        &--rotated {
          rotate: 180deg;
          transition: rotate 150ms;
        }
        &:hover {
          fill: var(--btn-link-neutral-color--hover);
        }
        &:active {
          fill: var(--btn-link-neutral-color--active);
        }
      }
      a {
        display: flex;
      }
    }
  }

  .details {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 364px;
    border-radius: 8px;
    font-family: $Poppins;
    gap: 24px;
    border: 1px solid var(--border-color);
    box-shadow: var(--main-shadow);
    color: var(--text-primary);
    background-color: var(--app-bg);

    @media (max-width: $screen-lg-max) {
      position: relative;
      top: unset;
      width: 100%;
      border: unset;
      padding-bottom: 0;
      box-shadow: unset;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: $PoppinsSemibold;

      svg {
        fill: var(--text-primary);
        height: 24px;
        width: 24px;
        cursor: pointer;
      }

      > div {
        display: flex;
        align-items: center;
        gap: 8px;
        a {
          height: 24px;
        }
      }
    }

    .amounts {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;

      > div {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .network-msg {
      display: flex;
      gap: 8px;
      background-color: var(--banner-bg);
      border: 1px solid var(--border-color);
      border-radius: 8px;
      padding: 12px;
      svg {
        flex-shrink: 0;
      }
      &__body {
        display: flex;
        flex-direction: column;
        gap: 4px;
        @include fontBody3;
        &__title {
          display: flex;
          align-items: center;
          font-family: $PoppinsBold;
          @include fontBody2;
        }
      }
    }

    .btns-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.warning-icon {
  fill: $error40 !important;
}

.wallet-address {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  svg {
    fill: var(--text-primary);
  }
}
