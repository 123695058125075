@import 'assets/scss/variables';
@import 'assets/scss/utils';

.providers-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .provider-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 16px;
    height: 64px;
    div {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &__text {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }
  }
}
.wallet-manual-msg {
  margin-top: 16px;
  font-size: $body3;
  color: var(--content-color);
  span {
    cursor: pointer;
  }
}
