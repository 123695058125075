@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.single-blog {
  gap: 36px;
  display: flex;
  padding: 0px 108px;
  flex-direction: column;

  @media (max-width: $screen-lg-max) {
    width: 100%;
    padding: 0px;
  }

  .link-contrast {
    background: transparent;
    color: var(--btn-link-contrast-color);
  }

  &__header {
    gap: 8px;
    display: flex;
    flex-direction: column;

    @media (max-width: $screen-lg-max) {
      margin-bottom: 24px;
    }

    @media (max-width: $screen-sm-max) {
      margin-bottom: 4px;

      .title {
        font-size: 28px;
        line-height: 40px;
      }
    }

    .badge-wrapper {
      gap: 12px;
      display: flex;
      margin-top: 8px;
      align-items: center;

      div {
        border-color: var(--text-primary);
      }
    }
  }

  &__img-wrapper {
    width: 100%;
    overflow: hidden;
    max-height: 510px;
    position: relative;
    margin-bottom: 42px;
    border-radius: 12px;
    aspect-ratio: 1.33 / 1;

    @media (max-width: $screen-sm-max) {
      margin-bottom: 4px;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    gap: 24px;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: $screen-md-max) {
      width: auto;
    }

    .text-wrapper {
      margin-bottom: 24px;

      p {
        font-size: 18px !important;
        font-family: $PoppinsSemibold, sans-serif;
        color: var(--text-primary) !important;
      }
    }

    b {
      @include fontH5;
      font-family: $PoppinsSemibold, sans-serif;
      color: var(--text-primary);
    }

    i {
      font-weight: 600;
      color: var(--text-primary);
    }

    div {
      @include fontBody1;
      font-family: $Poppins, sans-serif;
      color: var(--content-color);
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      div {
        @include fontBody2;
        color: var(--text-primary);
        font-family: $PoppinsSemibold, sans-serif;
      }
    }
  }

  &__author {
    gap: 8px;
    display: flex;
    cursor: pointer;
    align-items: center;

    img {
      object-fit: cover;
      border-radius: 50%;
    }

    svg {
      padding: 2px;
      border-radius: 20px;
      fill: var(--banner-svg);
      border: 1px solid var(--banner-svg);
    }

    &--info {
      display: flex;
      flex-direction: column;
    }
  }

  .wrapper {
    display: flex;
    margin-top: 48px;
    justify-content: center;

    .blogs-wrapper {
      gap: 24px;
      display: flex;
      flex-direction: column;

      .cards {
        gap: 32px;
        display: flex;

        @media (max-width: $screen-lg-max) {
          flex-direction: column;
        }
      }
    }
  }
}
