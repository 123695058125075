@import "assets/scss/general";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  button {
    padding: 0;
    width: 32px;
    height: 32px;
  }
}
