@import 'assets/scss/variables';
@import 'assets/scss/utils';

.nav {
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;

  button {
    padding: 0;
    height: unset;
    border-radius: 0px;
  }

  .nav-item {
    display: flex;
  }

  .styled {
    button {
      padding: 12px;
      border-radius: 12px;
    }
  }

  .continue-watching {
    &__wrapper {
      position: absolute;
      top: 100%;
      right: -50%;
      padding-top: 8px;
    }
    &__container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      min-width: 364px;
      background-color: var(--app-bg);
      border-radius: 16px;
      border: 1px solid var(--border-color);
      padding: 24px;
      color: var(--text-primary);
      box-shadow: var(--main-shadow);
    }
  }
}
