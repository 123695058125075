@import 'assets/scss/utils';
@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.results-wrapper {
  gap: 24px;
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-md-max) {
    gap: 64px;
    margin-top: 16px;
  }

  &__section {
    gap: 24px;
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-md-max) {
      gap: 40px;
    }

    .cards {
      display: grid;

      @media (min-width: $screen-md-max) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: $screen-lg-max) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
