@import 'assets/scss/variables';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.certificate {
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;

  &__img-wrapper {
    width: 100%;
    aspect-ratio: 1.33/1;
    overflow: hidden;
    border-radius: 8px;
    will-change: transform;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 350ms;

      &:hover {
        transform: scale(1.05);
      }
    }

    &--not-cropped {
      aspect-ratio: unset;
      > img {
        height: auto;
        object-fit: unset;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 4px;
    @include fontBody1;
    font-family: $PoppinsSemibold, sans-serif;
    color: var(--text-primary);
  }

  &__text {
    @include fontBody3;
    font-family: $Poppins, sans-serif;
    color: var(--content-color);
  }
}
