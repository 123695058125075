@import 'assets/scss/variables';
@import 'assets/scss/fonts';
@import 'assets/scss/utils';

.table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 48px;
  position: relative;
  z-index: 0;
  overflow-x: scroll;
  overflow-y: hidden;

  .table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      tr {
        background-color: var(--banner-bg);
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        border-radius: 8px;
        @include fontBody3;
        color: var(--text-primary);
        margin-bottom: 16px;

        th {
          display: flex;
          align-items: center;
          font-weight: 400;
          padding: 8px;
          height: 100%;

          &:first-child {
            padding-left: 16px;
          }

          &:last-child {
            padding-right: 16px;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      th {
        text-align: left;
      }
    }
    tbody {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .row {
        display: flex;
        align-items: center;
        border: 1px solid var(--border-color);
        border-radius: 12px;

        &--clickable {
          cursor: pointer;
        }

        td {
          padding: 16px 8px;
          text-align: left;
          position: relative;
          flex-grow: 1;
          color: var(--content-color);

          &:first-child {
            padding-left: 16px;
          }

          &:last-child {
            padding-right: 16px;
            display: flex;
            justify-content: flex-end;
          }
        }

        &:hover {
          background-color: var(--row-bg--hover);
          box-shadow: var(--main-shadow);
        }
      }

      .row:last-child {
        margin-bottom: 200px;
      }

      .selected {
        background-color: var(--row-bg--selected) !important;
        border: 1px solid var(--border-color-active);
      }
    }
  }

  .pagination {
    justify-content: flex-end;
  }
}

[data-theme='dark'] {
  --row-bg--hover: #{$black90};
  --row-bg--selected: #{$black80};
}
[data-theme='light'] {
  --row-bg--hover: #{$white10};
  --row-bg--selected: #{$white10};
}
