@import 'assets/scss/variables';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.tooltip {
  position: absolute;
  left: calc(100% + 16px);
  background-color: var(--tooltip-bg);
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  width: 100%;
  color: var(--text-primary-inverted);
  top: -60px;
  max-width: 322px;

  .arrow {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid var(--tooltip-bg);
    left: -8px;
    top: 45%;

    @media (max-width: $screen-lg-max) {
      left: 90%;
      top: 100%;
      right: 0;
      border-top: 8px solid var(--tooltip-bg);
      border-bottom: 8px solid transparent;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }

  &__title {
    @include fontBody2;
    font-family: $PoppinsSemibold, sans-serif;
  }

  &__content {
    @include fontBody3;
    font-family: $Poppins, sans-serif;
    display: flex;
    align-items: center;
    gap: 4px;
    word-break: keep-all;
    height: 24px;

    svg {
      fill: $success40;
    }
  }

  @media (max-width: $screen-lg-max) {
    width: 100%;
    left: auto;
    right: 0;
    bottom: calc(48px + 16px);
    z-index: 1;
    top: unset;
  }
}
