@import 'assets/scss/variables';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: center;
    width: 100%;

    .input {
      max-width: 160px;
    }

    .breakdown {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__header {
        display: flex;
        align-items: center;
        gap: 4px;
        font-family: $PoppinsSemibold;
        font-size: $body3;
        color: var(--text-primary);
        svg {
          fill: var(--content-color);
        }
      }
    }

    .btns-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      button {
        width: 100%;
        max-width: 288px;
      }
    }

    .price-per-video {
      &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }

      &__title {
        font-family: $PoppinsSemibold;
        font-size: $body3;
        color: var(--text-primary);
      }

      &__container {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 8px;

        .input {
          width: 100%;
          max-width: unset;
        }
      }

      &__currency {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--text-primary);
        border: 1px solid var(--border-color);
        border-radius: 8px;
        padding: 12px;
        width: 100%;
        max-width: 160px;
      }
    }
  }

  .icon {
    margin-top: 48px;

    svg {
      fill: $purple40;
    }
  }
}
