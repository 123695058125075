@import 'assets/scss/variables';
@import 'assets/scss/utils';

.selected {
  padding: 48px;
  display: grid;
  cursor: pointer;
  position: relative;
  border-radius: 16px;
  border: 1px solid var(--border-color);

  &--sm {
    grid-template-columns: 191px 1fr;
    padding: 16px 16px 16px 44px;
  }

  &--lg {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: $screen-lg-max) {
    gap: 24px;
    height: 420px;
    padding: 32px;
    grid-template-columns: auto;
  }

  &__img-col {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 146px;

    @media (max-width: $screen-lg-max) {
      max-width: 100%;
    }

    .dnd-icon {
      position: absolute;
      fill: var(--text-primary);
      top: calc(50% - 12px);
      left: 12px;
      z-index: 1;
      cursor: grab;
    }

    &__thumbnail {
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      border-radius: 8px;

      &--sm {
        height: 92px;
      }

      &--lg {
        height: 360px;
      }

      @media (max-width: $screen-lg-max) {
        height: 214px;
      }
    }
  }

  &__info-col {
    display: flex;
    align-items: center;
    color: var(--content-color);
    justify-content: space-between;

    @media (max-width: $screen-lg-max) {
      gap: 8px;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
