@import 'assets/scss/general';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;

    &__header {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &__badges {
        display: flex;
        gap: 4px;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;

      .btns-container {
        display: flex;
        justify-content: space-between;

        @media (max-width: $screen-md-max) {
          flex-direction: column-reverse;
          gap: 8px;
        }
      }
    }

    > div {
      flex: 1;
    }

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }
  }
}
