@import 'assets/scss/variables';
@import 'assets/scss/utils';

.switch__container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  .switch {
    position: relative;
    display: inline-block;
    height: var(--sw-height);
    width: var(--sw-width);
    border-radius: 40px;

    input {
      opacity: 0;
      height: var(--sw-height);
      width: var(--sw-width);
      margin: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 40px;
      background-color: var(--sw-neutral-bg);
      -webkit-transition: 0.4s;
      transition: 0.4s;

      &:hover {
        background-color: var(--sw-neutral-bg-hover);
      }
    }

    .slider:before {
      position: absolute;
      content: '';
      height: var(--dimensions);
      width: var(--dimensions);
      left: var(--slider-left);
      top: var(--slider-top);
      border-radius: 50%;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider--active {
      background: var(--contrast-bg);
      &:hover {
        background: var(--contrast-bg-hover);
      }
    }

    .slider--disabled {
      background: var(--bg-disabled);
      cursor: default;
      &:hover {
        background: var(--bg-disabled);
      }
      &::before {
        background-color: var(--circle-bg-disabled);
      }
    }

    input:checked + .slider {
      // background-color: var(--contrast-bg);
    }

    input:checked + .slider:before {
      -ms-transform: translateX(var(--translateX));
      transform: translateX(var(--translateX));
    }

    &__label {
      font-family: $PoppinsSemibold;
      white-space: nowrap;
      font-size: var(--font-size);
      color: var(--text-primary);
      text-wrap: wrap;
    }
  }
}

[data-theme='light'] {
  --contrast-bg: #{$purple40};
  --contrast-bg-hover: #{$purple30};
  --sw-neutral-bg: #{$white50};
  --sw-neutral-bg-hover: #{$white60};
  --bg-disabled: #{$white50};
  --circle-bg-disabled: #{$white30};
}

[data-theme='dark'] {
  --contrast-bg: #{$purple50};
  --contrast-bg-hover: #{$purple70};
  --sw-neutral-bg: #{$black50};
  --sw-neutral-bg-hover: #{$black20};
  --bg-disabled: #{$black50};
  --circle-bg-disabled: #{$black30};
}

[data-size='sm'] {
  --dimensions: 15px;
  --translateX: 13px;
  --sw-height: 20px;
  --sw-width: 32px;
  --slider-left: 2px;
  --slider-top: 3px;
  --font-size: #{$body3};
}

[data-size='lg'] {
  --dimensions: 24px;
  --translateX: 20px;
  --sw-height: 28px;
  --sw-width: 48px;
  --slider-left: 2px;
  --slider-top: 2px;
  --font-size: #{$body2};
}
