@import 'assets/scss/utils';
@import 'assets/scss/variables';

.popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .content {
    width: 304px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
  }

  .reason {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .card {
    width: 304px;
  }
}
