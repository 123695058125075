@import 'assets/scss/variables';
@import 'assets/scss/utils';

.email {
  display: flex;
  flex-direction: column;
  gap: 24px;
  &__header {
    text-align: center;
    margin-bottom: 24px;
  }
  &__subheader {
    display: flex;
    gap: 4px;
  }
  &__btns-container {
    display: flex;
    flex-direction: column;
  }
}
