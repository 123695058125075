@import 'assets/scss/general';

.form {
  flex: 1;
  gap: 40px;
  justify-content: space-between;

  .fields-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.first-step {
  margin-top: 16px;
}

.user-info-fields {
  gap: 24px;
  display: flex;
  flex-direction: column;

  @media (max-width: $screen-md-max) {
    max-height: 430px;
    overflow: scroll;
  }

  .occupation-wrapper {
    max-width: 80%;
  }

  &__wrapper {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;

    &__button {
      gap: 8px;
      height: 40px;
      display: flex;
      cursor: pointer;
      font-size: 14px;
      padding: 10px 24px;
      align-items: center;
      border-radius: 16px;
      color: var(--text-primary);
      background-color: var(--secondary-bg);

      img {
        width: 20px;
        height: 20px;
        filter: brightness(0) saturate(100%) invert(42%) sepia(45%) saturate(9%)
          hue-rotate(60deg) brightness(93%) contrast(88%);
      }
    }

    .selected {
      background-color: rgba($purple10, 0.42);
      border: 2px solid var(--btn-contrast-bg);

      img {
        filter: initial;
      }
    }
  }

  &__usage {
    gap: 16px;
    display: flex;
    max-width: 400px;
    flex-direction: column;

    div {
      text-align: center;
      justify-content: center;
    }

    .selected {
      background-color: rgba($purple10, 0.42);
      border: 2px solid var(--btn-contrast-bg);

      img {
        filter: initial;
      }
    }
  }

  .roles {
    margin-top: 24px;
  }
}

.btns-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $screen-md-max) {
    gap: 16px;
    flex-direction: column;
  }

  .btns-wrapper {
    display: flex;
    align-items: center;
    button {
      flex: initial !important;
    }
  }
}
