@import 'assets/scss/utils';
@import 'assets/scss/variables';

.menu {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__change {
      display: grid;
      grid-template-rows: auto auto auto;

      &__row {
        display: grid;
        grid-template-columns: auto 6fr 1fr;
        gap: 24px;
        z-index: 1;

        &__values {
          display: flex;
          gap: 4px;
          align-items: flex-start;
          svg {
            flex-shrink: 0;
            fill: var(--text-primary);
          }
        }

        &__badge {
          display: flex;
          flex-direction: column;
        }
      }

      .divider {
        min-height: 24px;
        height: 100%;
        margin-left: 24px;
      }
    }
  }
}
