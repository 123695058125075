@import 'assets/scss/utils';
@import 'assets/scss/variables';
@import 'assets/scss/general';

.wrapper {
  gap: 8px;
  display: flex;
  margin-top: 16px;
  align-items: center;
  flex-direction: column;

  .subtitle {
    color: var(--content-color);
  }

  .content {
    gap: 24px;
    width: 100%;
    display: flex;
    margin: 16px 0px;
    flex-direction: column;
  }

  .btns-container {
    gap: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  h5 {
    text-align: center;
  }
}
