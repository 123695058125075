@import 'assets/scss/utils';
@import 'assets/scss/variables';

.menu {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    overflow-wrap: anywhere;
  }

  h4 {
    text-align: center;
    font-family: $PoppinsSemibold;
  }

  &__close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 40px !important;
    height: 40px !important;
    padding: 8px;
  }
}
