@import 'assets/scss/variables';
@import 'assets/scss/utils';
@import 'assets/scss/general';
@import 'assets/scss/mixins';

.input__group {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &--row {
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: $screen-md-max) {
    width: 100%;
  }

  .password__group {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }

  .input__field-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .input__icon {
    position: absolute;
    top: 12px;
    fill: var(--input-text);
    z-index: 1;
    left: 12px;
    &--right {
      left: unset;
      right: 16px;
      cursor: pointer;
    }
  }

  .input__field {
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;
    height: 48px;
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    color: var(--input-text);
    @include fontBody2;
    transition: all 150ms ease-out;

    &:focus {
      color: var(--input-text-active);
      -webkit-text-fill-color: var(--input-text-active);
      opacity: 1; /* required on iOS */
    }

    &:disabled {
      color: var(--input-text-disabled);
      -webkit-text-fill-color: var(--input-text-disabled);
      opacity: 1; /* required on iOS */
      resize: none;
    }

    &--neutral {
      background: var(--neutral-bg);
      border: unset;
      &:hover {
        background: var(--neutral-bg-hover);
      }
      &:focus {
        background: var(--neutral-bg-active);
      }
      &:disabled {
        background: var(--neutral-bg-disabled);
      }
    }

    &--outline {
      background: transparent;
      border: 1px solid var(--input-border-color);

      &:hover {
        border: 1px solid var(--input-border-color-hover);
      }
      &:focus {
        border: 1px solid var(--input-border-color-active);
      }
      &:disabled {
        border: 1px solid var(--input-border-color-disabled);
      }
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: var(--text-primary) !important;
      transition: background-color 5000s ease-in-out 0s;
    }

    &--p-left {
      padding: 12px 16px 12px 42px;
    }

    &--p-right {
      padding: 12px 42px 12px 16px;
    }

    &--textarea {
      font-family: inherit;
      height: unset;
      resize: vertical;
    }

    &--checkbox {
      display: flex;
      flex-direction: column;
      gap: 4px;
      position: relative;
      font-family: 'Poppins Medium', sans-serif;
      font-size: 14px;
      cursor: pointer;
      color: var(--text-primary);

      &--status {
        right: 16px;
        bottom: 9px;
        position: absolute;
        color: var(--content-color);
      }

      &--checked {
        border: 1px solid $purple50 !important;
      }

      &--success {
        border: 1px solid $success40 !important;
        background-color: var(--success-contrast-bg);
      }

      &--error {
        border: 1px solid $error40 !important;
        background-color: var(--error-contrast-bg);
      }

      > div {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      > p {
        margin-left: 25px;
        color: var(--text-primary);
        font-family: $Poppins, sans-serif;
      }

      &--disabled {
        color: var(--text-primary-inactive);

        > p {
          color: var(--text-primary-inactive);
        }
      }

      /* Hide the default checkbox */
      input {
        visibility: hidden;
        cursor: pointer;
        width: 16px;
        height: 16px;
        margin: 0;

        /* Show the mark when checked */
        &:checked ~ .input__field-checkbox__mark:after {
          display: block;
        }
      }

      /* Create a custom checkbox */
      .input__field-checkbox__mark {
        position: absolute;
        height: 16px;
        width: 16px;
        background-color: transparent;
        border-radius: 4px;

        /* Create the mark/indicator (hidden when not checked) */
        &:after {
          content: '';
          position: absolute;
          display: none;
          left: 4px;
          top: 0;
          width: 3px;
          height: 7px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }

      &--radio {
        .input__field-checkbox__mark {
          border-radius: 50%;
        }
      }

      input ~ .input__field-checkbox__mark {
        background-color: transparent;
        border-width: 2px;
        border-color: var(--text-primary-inactive);
        border-style: solid;
        transition:
          border 150ms ease-out,
          background-color 150ms ease-out;
      }

      input:hover ~ .input__field-checkbox__mark {
        border-color: var(--text-primary);
      }

      input:checked ~ .input__field-checkbox__mark {
        background-color: $purple50;
        border: 2px solid $purple50;
      }

      input:checked:disabled ~ .input__field-checkbox__mark {
        background-color: var(--text-primary-inactive);
        border-color: var(--text-primary-inactive);
      }

      input:disabled ~ .input__field-checkbox__mark {
        background-color: transparent;
        border-color: var(--text-primary-inactive);
      }
    }

    &--file {
      &--upload {
        button {
          padding: 0;
          height: unset;
        }
        input {
          display: none;
        }
      }
      &--uploaded {
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          cursor: pointer;
        }
      }
    }

    &--error {
      border: 1px solid $error40;
    }
  }

  &--flex-1-1 {
    display: flex;
    gap: 8px;

    > div {
      flex: 1;
    }
  }

  &--flex-1-2 {
    display: flex;
    gap: 8px;

    > div:first-child {
      flex: 1;
    }

    > div:last-child {
      flex: 2;
    }
  }

  &--flex-2-1 {
    display: flex;
    gap: 8px;

    > div:first-child {
      flex: 2;
    }

    > div:last-child {
      flex: 1;
    }
  }
}

// Active color to non-empty fields
.input-not-empty {
  color: var(--input-text-active) !important;
}

[data-theme='light'] {
  --neutral-bg: #{$white30};
  --neutral-bg-hover: #{$white40};
  --error-contrast-bg: #fcf7f9;
  --success-contrast-bg: #f3faf6;
  --neutral-bg-active: #{$white40};
  --neutral-bg-disabled: #{$white30};
}

[data-theme='dark'] {
  --neutral-bg: #{$black80};
  --neutral-bg-hover: #{$black70};
  --error-contrast-bg: #a401280d;
  --success-contrast-bg: #0da0480d;
  --neutral-bg-active: #{$black70};
  --neutral-bg-disabled: #{$black80};
}
