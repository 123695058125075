@import "assets/scss/general";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 24px 0;

  img {
    width: 200px;
    height: auto;
  }
}
