@import 'assets/scss/variables';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.notifications {
  position: relative;

  &__btn {
    position: relative;
    &__counter {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -2px;
      right: -2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $purple50;
      color: #ffffff;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 16px;
    border-radius: 8px;

    @media (max-width: $screen-lg-max) {
      border-radius: 0 0 16px 16px;
      box-shadow: 0px 0px 14px 0px #0000000f;
      background-color: var(--app-bg);
    }

    &__wrapper {
      position: absolute;
      padding-top: 8px;
      top: calc(100% + 8px);
      right: 0;
      width: 364px;
      border-radius: 8px;
      border: 1px solid var(--border-color);
      background-color: var(--app-bg);
      box-shadow: var(--main-shadow);

      @media (max-width: $screen-lg-max) {
        top: 80px;
        left: 0;
        width: 100%;
        z-index: 10;
        height: 0px;
        overflow-y: hidden;
        transition: height 300ms;
        border: unset;
        padding-top: 0px;
        background-color: unset;

        &--open {
          height: calc(100vh - 72px);
          overflow: scroll;
          transition: height 300ms;
        }
      }
    }

    &__support {
      position: absolute;
      right: 0;
      top: calc(100%);
      width: 140px;
      height: 8px;
    }

    &__list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-height: 396px;
      overflow: scroll;
      scrollbar-width: none;

      @media (max-width: $screen-lg-max) {
        max-height: unset;
        width: 100%;
      }

      &--empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        text-align: center;

        svg {
          fill: var(--scrollbar-bg);
        }
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: 8px;
      cursor: pointer;
      border: 1px solid var(--border-color);
      border-radius: 16px;
      padding: 16px;

      &__text {
        color: var(--text-primary);
        span {
          font-family: $PoppinsSemibold;
        }

        &--seen {
          color: var(--content-color);
        }
      }

      &__date {
        color: var(--content-color);
        text-align: right;
      }
    }

    h5 {
      font-family: $PoppinsSemibold;
    }

    button {
      margin-top: auto;
    }
  }
}
