@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.creator-report {
  &__table-wrapper {
    gap: 24px;
    width: 100%;
    display: flex;

    @media (max-width: $screen-md-max) {
      display: block;
    }
  }

  &__actions-wrapper {
    width: 270px;

    @media (max-width: $screen-md-max) {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  &__no-results {
    width: 100%;
  }

  &__cards-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
  }

  &__cards-container {
    flex: 2;
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: $screen-xxl-max) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $screen-lg-max) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__card {
    gap: 16px;
    display: flex;
    height: 200px;
    padding: 32px;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--info-box);

    @media (max-width: $screen-xxl-max) {
      height: auto;
    }

    .loader {
      width: unset;
    }

    &__value {
      font-family: $PoppinsSemibold;
      height: 64px;
    }

    &__change {
      display: flex;
      gap: 4px;
      height: 21px;
      color: var(--text-primary);
    }
  }

  &__audience {
    width: 50%;

    @media (max-width: $screen-xl-max) {
      width: 100%;
    }

    tbody {
      tr {
        margin-bottom: 0px !important;
      }
    }
  }
}
