@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.form-wrapper {
  gap: 64px;

  .form {
    width: 100%;

    h4:first-child {
      display: none;
    }
  }
}

.bundle-form-step {
  gap: 40px;
  width: 650px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: $screen-md-max) {
    width: auto;
    margin: initial;
  }

  h5 {
    margin-bottom: 4px;
  }

  .row-card {
    display: flex;
    align-items: center;
    flex-direction: column;

    div {
      width: 100%;
    }

    svg {
      z-index: 1;
      margin-top: -20px;
      margin-bottom: -20px;
    }
  }
}

.summary-step {
  gap: 16px;
  width: 650px;
  margin: auto;

  @media (max-width: $screen-md-max) {
    width: auto;
    margin: initial;
  }
}

.course-bundle {
  display: flex;
  width: 100%;

  &--grid {
    display: flex;
    gap: 16px;

    @media (max-width: $screen-md-max) {
      display: block;
      width: 100%;
    }
  }

  &__table-wrapper {
    gap: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__action-btns {
    display: flex;
    gap: 8px;
    margin-left: 8px;

    .active {
      background-color: var(--btn-neutral-bg--active) !important;
    }
  }
}
