@import 'assets/scss/variables';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.tabs__container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  scroll-margin: 80px; // Offset for fixed header

  @media (max-width: $screen-md-max) {
    gap: 16px;
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
    font-size: var(--font-size);
    font-family: $PoppinsSemibold;
    border-bottom: 1px solid var(--border-color);
    transition: border 150ms ease-out;

    &__item-wrapper {
      &--vertical {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;
      }
    }

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      text-align: center;
      padding: 12px;
      gap: 8px;
      cursor: pointer;
      height: 100%;
      color: var(--content-color);
      transition: color 150ms ease-out;
      text-wrap: nowrap;

      &__icon {
        width: 18px;
        height: 18px;
        fill: var(--content-color);
        transform: rotate(180deg);
        transition: fill 150ms ease-out;
      }

      &--active {
        color: var(--text-primary);
        border-bottom: 3px solid $purple50;

        .tabs__item__icon {
          fill: var(--text-primary);
        }
      }

      &:hover {
        color: var(--text-primary);
      }

      &__name {
        display: flex;
        align-items: center;
        gap: 4px;

        &--mobile {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    &__content {
      color: var(--text-primary);
    }
  }

  .tabs-mobile::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  .tabs-mobile {
    overflow-x: scroll;
    margin-left: -24px;
    padding: 0 24px;
    width: calc(100% + 48px);
  }

  &--vertical {
    flex-direction: row;
    flex: auto;
    min-height: 400px;

    .tabs {
      flex-direction: column;
      gap: 8px;
      flex: 1;
      border: none;
      height: 56px;

      &__item {
        width: 100%;
        border-radius: 8px;
        padding: 22px 16px;
        transition:
          background-color 150ms ease-out,
          color 150ms ease-out;

        &:hover {
          background-color: var(--secondary-bg);
        }
        &--active {
          border: none;
          background-color: var(--scrollbar-bg);
        }

        &--danger {
          color: $error40;
        }
      }

      &__content {
        flex: 3;
      }
    }

    @media (max-width: $screen-lg-max) {
      flex-direction: column;
    }
  }
  &--disabled {
    pointer-events: none;
  }
}

[data-size='sm'] {
  --font-size: #{$body2};
}

[data-size='lg'] {
  --font-size: #{$body1};
}
