@import "assets/scss/variables";
@import "assets/scss/utils";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 1 auto;
}

