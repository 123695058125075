@import 'assets/scss/variables';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.c-datepicker {
  :global(.react-datepicker-popper) {
    z-index: 99;
  }

  :global(.react-datepicker) {
    background: var(--datepicker-bg);
    box-shadow: var(--main-shadow);
    border: 1px solid var(--border-color);
    border-radius: 16px;
    font-family: 'Poppins Regular', sans-serif;
  }
  :global(.react-datepicker__header) {
    background-color: transparent;
    border: none;
    padding: 16px 0 0;
    :global(.react-datepicker__current-month),
    :global(.react-datepicker-time__header),
    :global(.react-datepicker-year-header) {
      color: var(--datepicker-color);
      font-family: 'Poppins Bold', sans-serif;
      font-size: $body1;
    }
    :global(.react-datepicker__day-name) {
      font-family: 'Poppins Bold', sans-serif;
      font-size: $body2;
    }
  }
  :global(.react-datepicker__navigation-icon) {
    top: 8px;
  }
  :global(.react-datepicker__year-read-view--down-arrow),
  :global(.react-datepicker__month-read-view--down-arrow),
  :global(.react-datepicker__month-year-read-view--down-arrow),
  :global(.react-datepicker__navigation-icon::before) {
    border-color: var(--datepicker-arrow);
    width: 5px;
    height: 5px;
    border-width: 2px 2px 0 0;
  }
  :global(.react-datepicker__day-name),
  :global(.react-datepicker__day),
  :global(.react-datepicker__time-name) {
    color: var(--datepicker-color);
    width: 40px;
    line-height: 40px;
    margin: 8px;

    @media (max-width: $screen-sm-max) {
      width: 30px;
      line-height: 30px;
      margin: 6px;
    }
  }
  :global(.react-datepicker__day:hover) {
    border-radius: 50%;
    background-color: var(--datepicker-hover-color);
    color: var(--datepicker-color);
  }
  :global(.react-datepicker__day--disabled),
  :global(.react-datepicker__month-text--disabled),
  :global(.react-datepicker__quarter-text--disabled),
  :global(.react-datepicker__year-text--disabled) {
    color: var(--datepicker-disabled-color);
  }
  :global(.react-datepicker__day--disabled:hover),
  :global(.react-datepicker__month-text--disabled:hover),
  :global(.react-datepicker__quarter-text--disabled:hover),
  :global(.react-datepicker__year-text--disabled:hover) {
    background-color: transparent;
  }
  :global(.react-datepicker__day--in-range),
  :global(.react-datepicker__day--keyboard-in-range) {
    background-color: $purple50;
    color: $lightBlue10;
    font-weight: 400;
    border-radius: 50%;
  }
  :global(.react-datepicker__day--in-selecting-range),
  :global(.react-datepicker__day--keyboard-in-selecting-range) {
    background-color: $purple50;
    color: $lightBlue10;
    font-weight: 400;
    border-radius: 50%;
  }
  :global(.react-datepicker__day--in-range:hover),
  :global(.react-datepicker__day--keyboard-in-range:hover) {
    background-color: $purple50;
    color: $lightBlue10;
    font-weight: 400;
    border-radius: 50%;
  }
  :global(.react-datepicker__day--selected) {
    background-color: $purple50;
    color: $lightBlue10;
    font-weight: 400;
    border-radius: 50%;
  }
  :global(.react-datepicker__day--keyboard-selected) {
    background-color: $purple50;
    color: $lightBlue10;
    font-weight: 400;
    border-radius: 50%;
  }
  :global(.react-datepicker__day--selected:hover) {
    background-color: $purple50;
  }
  :global(.react-datepicker__day--keyboard-selected:hover) {
    background-color: $purple50;
  }
  :global(.react-datepicker__triangle) {
    display: none;
  }

  :global(.react-datepicker__close-icon) {
    right: 6px;
  }

  :global(.react-datepicker__close-icon::after) {
    background: transparent;
    font-size: 24px;
  }

  :global(.react-datepicker__children-container) {
    width: auto;
  }

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }

  :global(.button-wrapper) {
    border: none;

    :global(.text-wrapper) {
      gap: 8px;
      display: flex;
      align-items: center;
    }

    :global(.Button_text-wrapper__Faozy) {
      justify-content: start;
    }
  }

  .children-wrapper {
    padding: 12px 16px;
    clear: both;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;

    .time-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }

    button {
      width: 288px;
      height: 40px;
      padding: 0px;
    }
  }
}

[data-theme='light'] {
  --datepicker-bg: #{$white10};
  --datepicker-color: #{$darkBlue100};
}

[data-theme='dark'] {
  --datepicker-bg: #{$darkBlue100};
  --datepicker-color: #{$lightBlue10};
  --datepicker-arrow: #{$lightBlue10};
}
