@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.popup-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  box-shadow: var(--wrapper-shadow);
  z-index: 100;

  .backdrop {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--wrapper-bg);
    backdrop-filter: blur(8px);
    position: fixed;
    z-index: 10;
    cursor: pointer;
  }

  .content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    background: var(--app-bg);
    padding: 24px;
    border-radius: 12px;
    z-index: 100;

    button {
      z-index: 1;
    }

    &.rectangle {
      width: 60%;
      height: 90%;
      overflow-y: scroll;
    }

    &.sm {
      width: 384px;
    }
    &.md {
      width: 512px;
    }
    &.lg {
      width: 640px;
    }
    &.xl {
      width: 768px;
    }

    .close-icon {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    @media (max-width: $screen-md-max) {
      width: 90vw !important;
      max-height: 90vh !important;
      overflow: scroll;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }
}

[data-theme='light'] {
  --wrapper-bg: rgba(236, 238, 245, 0.5);
}
[data-theme='dark'] {
  --wrapper-bg: rgba(8, 9, 24, 0.7);
}
