@import "assets/scss/utils";

.wrapper {
  height: 100%;
  padding: 24px;
  background: url("../../assets/images/shapes.svg") var(--app-bg) no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;
    text-align: center;

    > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .logo {
      position: absolute;
      top: 12px;
      left: 24px;
      cursor: pointer;
    }

    @media (max-width: $screen-md-max) {
      gap: 24px;

      img {
        width: 150px;
      }

      h1 {
        font-size: $h3;
        line-height: $lhH3;
      }

      p {
        font-size: $body2;
      }
    }
  }
}
