@import 'assets/scss/variables';
@import 'assets/scss/fonts';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.report {
  gap: 6px;
  display: flex;
  align-items: center;

  div {
    display: flex;
  }
}
