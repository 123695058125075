@import 'assets/scss/utils';
@import 'assets/scss/variables';

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    margin-top: 64px;
    width: 320px;

    @media (max-width: $screen-lg-max) {
      width: 240px;
    }
  }

  &__title {
    margin-top: 24px;
    font-family: $PoppinsSemibold;
    text-align: center;
  }

  &__msg {
    margin-top: 16px;
    color: var(--content-color);
    text-align: center;
  }

  > button {
    margin-top: 100px;
    margin-top: 64px;
    margin-left: auto;
  }
}
