@import 'assets/scss/variables';
@import 'assets/scss/fonts';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.container {
  margin: 0 auto;
  padding: 140px 0 60px;

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
  }

  @media (max-width: $screen-lg-max) {
    padding: 100px 0 60px;
  }
}
