@import 'assets/scss/utils';
@import 'assets/scss/variables';
@import 'assets/scss/general';

.browse-bundles {
  display: flex;
  width: 100%;
  gap: 16px;

  @media (max-width: $screen-md-max) {
    justify-content: center;
  }

  &__grid {
    @media (max-width: $screen-md-max) {
      display: block;
      width: 100%;
    }
  }

  &__table-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    overflow: hidden;
  }

  &__action-btns {
    display: flex;
    gap: 8px;
    margin-left: 8px;

    .active {
      background-color: var(--btn-neutral-bg--active) !important;
    }
  }
}
