@import 'assets/scss/variables';
@import 'assets/scss/utils';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 1 auto;
  min-width: 720px;

  @media (max-width: $screen-lg-max) {
    min-width: 640px;
  }

  @media (max-width: $screen-md-max) {
    width: 100%;
    min-width: unset;
  }
}
