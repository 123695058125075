@import 'assets/scss/utils';

.filter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  width: 100%;
  gap: 18px;
  background-color: var(--app-bg);

  &__body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: var(--text-primary);
  }

  &__arrow {
    fill: var(--content-color);
    transition: transform 150ms ease-out;

    &--down {
      transform: rotate(180deg);
    }
  }

  &__buttons {
    margin-top: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  &__slider {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }

    div {
      position: relative;

      input {
        width: 125px !important;
        padding-right: 50px !important;
        color: #{$black30} !important;

        @media (max-width: $screen-md-max) {
          width: 100% !important;
        }
      }

      span {
        position: absolute;
        top: 14px;
        left: 75px;
        color: #{$black30};
        background-color: var(--app-bg);

        @media (max-width: $screen-md-max) {
          left: 75%;
        }
      }
    }

    hr {
      width: 16px;
    }
  }
}
