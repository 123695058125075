@import 'assets/scss/variables';
@import 'assets/scss/utils';

.table {
  gap: 16px;
  display: flex;
  flex-direction: column;

  &__header {
    &__row {
      height: 40px;
      display: flex;
      padding: 8px 16px;
      border-radius: 8px;
      align-items: center;
      transition: border 150ms ease-out;
      border: 1px solid var(--border-color);

      &__badge {
        flex: 1;
        display: flex;

        &__label {
          width: 70px;
          height: 18px;
          background-color: var(--banner-bg);
          border-radius: 4px;
        }
      }

      &__badge:first-child {
        flex: 1;
      }

      &__badge:last-child {
        flex: 0;
      }

      &__badge-image {
        flex: 2;
      }
    }
  }

  &__row {
    display: flex;
    padding: 16px;
    align-items: center;
    border-radius: 12px;
    transition: border 150ms ease-out;
    border: 1px solid var(--border-color);

    &--sm {
      min-height: 60px;
    }

    &--md {
      min-height: 94px;
    }

    &__img-wrapper {
      flex: 2;
      gap: 16px;
      display: flex;
      align-items: center;

      &__img {
        width: 146px;
        height: 92px;
        border-radius: 12px;
        background-color: var(--banner-bg);
      }

      &__badge {
        gap: 16px;
        display: flex;
        flex-direction: column;

        div:first-child {
          width: 150px;
          height: 18px;
          background-color: var(--banner-bg);
          border-radius: 4px;
        }

        div:last-child {
          width: 70px;
          height: 18px;
          background-color: var(--banner-bg);
          border-radius: 4px;
        }
      }
    }

    &__badge {
      flex: 1;
      display: flex;

      &__label {
        width: 100px;
        height: 18px;
        background-color: var(--banner-bg);
        border-radius: 4px;
      }
    }

    &__badge:last-child {
      flex: 0;
    }
  }

  &__button {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background-color: var(--banner-bg);
  }
}
