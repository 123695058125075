@import 'assets/scss/variables';
@import 'assets/scss/mixins';
@import 'assets/scss/utils';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  input[type='file'] {
    display: none;
  }

  .attachments-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    background: var(--secondary-bg);

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > button {
        width: 24px !important;
        height: 24px !important;
        padding: 0;
      }
    }

    &__title {
      color: var(--text-primary);
      font-family: $Poppins;
      @include fontBody2;
    }
  }

  .input__error__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    button {
      height: unset;
      width: unset;
    }
  }
}
