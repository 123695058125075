@import 'assets/scss/general';
@import 'assets/scss/utils';

.test {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--border-color);
    border-radius: 16px;
    padding: 16px;
    max-width: 768px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      svg {
        fill: var(--content-color-inactive) !important;
      }
    }

    &__title-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-and-badge {
        display: flex;
        flex-wrap: wrap-reverse;
        gap: 8px;
      }

      .arrow {
        display: block;
        justify-self: flex-end;
        rotate: 180deg;
        height: unset;

        &--open {
          rotate: 0deg;
        }
      }
    }

    &__subtitle-container {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--content-color);

      @media (max-width: $screen-sm-max) {
        align-items: start;
        flex-direction: column;
      }
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .exam {
    &__wrapper {
      display: none;
      flex-direction: column;
      gap: 12px;
      max-height: 0px;
      overflow: hidden;

      &--open {
        display: flex;
        max-height: unset;
      }

      .btns-container {
        display: flex;
        gap: 8px;
        margin-top: 24px;
      }
    }
    &__container {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
