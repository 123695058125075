.btns-row {
  display: flex;
  gap: 8px;
  width: 100%;

  > div {
    width: 100%;
  }

  &__primary {
    width: 100%;
    > div {
      width: 100%;
    }
  }
}
