@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.continue-watching {
  margin-bottom: 56px;
}

.user-info {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  margin: -60px 0 48px 16px;

  .user-info__wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
    overflow: hidden;
  }

  .user {
    &__main {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 0;
    }

    &__socials {
      gap: 8px;
      display: flex;
      align-items: center;

      @media (max-width: $screen-xl-max) {
        max-width: 320px;
        overflow-x: scroll;
        scrollbar-width: none;
      }

      @media (max-width: $screen-lg-max) {
        overflow: inherit;
        max-width: unset;
      }

      &__item {
        color: var(--content-color);
        transition: color 150ms ease-out;

        > a {
          display: flex;
          align-items: center;
          gap: 4px;

          svg {
            fill: var(--content-color);
            transition: fill 150ms ease-out;
          }
        }

        &:hover {
          color: var(--text-primary);

          a {
            svg {
              fill: var(--text-primary);
            }
          }
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 8px;

      &--mobile {
        display: none;
        flex-direction: column;
        width: 100%;

        > button {
          width: 100%;
        }

        .user__actions__group {
          display: flex;
          align-items: center;
          gap: 8px;
          width: 100%;

          @media (max-width: $screen-sm-max) {
            flex-direction: column;

            > button {
              width: 100%;
            }
          }

          > button {
            flex: 1 1;
          }
        }
      }
    }
  }

  @media (max-width: $screen-lg-max) {
    flex-direction: column;
    align-items: center;
    margin: -100px 0 48px 0px;
    gap: 0;

    .user-info__wrapper {
      flex-direction: column;
    }

    .user__main {
      align-items: center;

      > div {
        text-align: center;
      }
    }

    .user__actions {
      display: none;

      &--mobile {
        display: flex;
      }
    }
  }
}
