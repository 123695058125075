@import 'assets/scss/variables';
@import 'assets/scss/fonts';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.price {
  display: flex;
  align-items: center;
  gap: 4px;

  div {
    display: flex;
  }
}

.status {

  h5 {
    border-radius: 4px;
    color: var(--app-bg);
    padding: 2px 4px;
    font-size: var(--font-size);
    font-family: $PoppinsSemibold;
  }

  &__claim {
    div {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 8px;
    }
  }

  &__purchased {
    background: $success40;  
  }

  &__locked {
    width: fit-content;
    background: $error40;
  }

  &__manual-completed {
    gap: 4px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__manual-refund {
    gap: 12px;
    display: flex;
    align-items: center;
  }

  &__claimed {
    background: $success40;
  }
  
  &__manual {
    padding: 6px 4px !important;
    background: $warning40;
  }

  &__refund {
    width: 133px;
    background: $darkBlue10;
  }

  &__ready {
    color: $darkBlue10 !important;
    font-family: $Poppins, sans-serif !important;
  }
}

.context-menu {
  &__actions {
    div {
      gap: 8px;
      display: flex;
      flex-direction: column;
    }
  }
}
