@import 'assets/scss/variables';
@import 'assets/scss/utils';

.user {
  position: relative;
  display: flex;
  .user-menu-wrapper {
    position: absolute;
    top: 100%;
    padding-top: 8px;
    right: 0;
    z-index: 1;

    .user-menu {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;
      border-radius: 8px;
      background-color: var(--app-bg);
      border: 1px solid var(--border-color);
      width: 364px;
      box-shadow: var(--main-shadow);
      color: var(--text-primary);

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        text-align: center;
      }

      .continue-watching-card {
        display: flex;
        justify-content: flex-start;
        width: 100%;
      }

      .rows-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .user-menu-row {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 12px 16px;
          color: var(--content-color);
          cursor: pointer;

          &--text {
            display: flex;
            align-content: center;
            gap: 4px;

            > div {
              display: flex;
              align-items: center;
              @include fontBody2;
            }
          }
          &--amount {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            background-color: $purple50;
            width: 24px;
            height: 24px;
            color: $white10;
          }
          svg {
            fill: var(--content-color);
          }
        }
      }

      .theme-btn {
        color: var(--content-color);
        font-family: $Poppins;
        svg {
          fill: var(--content-color);
        }
      }
    }
    .accessibility-support {
      position: absolute;
      right: 48px;
      top: -48px;
      width: 48px;
      height: 56px;
      z-index: -1;
    }
  }

  .avatar {
    &__error {
      img {
        border: 2px solid #{$error50};
      }
    }

    &__warning {
      img {
        border: 2px solid #{$warning50};
      }
    }
  }
}
