@import 'assets/scss/utils';
@import 'assets/scss/variables';

.menu {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    &__change {
      display: grid;
      grid-template-rows: auto auto;

      &__container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
      }

      &__list {
        display: flex;
        flex-direction: column;
      }

      &__row {
        display: grid;
        grid-template-columns: auto 4fr;
        gap: 24px;
        z-index: 1;

        .exam {
          display: flex;
          flex-direction: column;
          gap: 8px;

          &__question {
            font-family: $PoppinsSemibold;
            color: var(--text-primary);
          }

          &__answer {
            color: var(--content-color);

            &--correct {
              border: 1px solid #{$success40} !important;
              color: #{$success40};
            }
          }
          div {
            padding: 8px 12px;
            border: 1px solid var(--border-color);
            border-radius: 12px;
          }
        }

        &__badge {
          display: flex;
          flex-direction: column;
        }
      }

      .divider {
        margin-left: 32px;
        &--full-height {
          height: 100%;
        }
      }
    }
  }
}
