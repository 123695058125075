@import 'assets/scss/variables';
@import 'assets/scss/utils';

.tooltip {
  &__comp {
    position: relative;
    display: inline-block;
    overflow-wrap: break-word;
  }

  &__el {
    display: flex;
    cursor: pointer;
    margin: 0;
  }

  &__wrapper {
    color: var(--text-primary-inverted) !important;
    background: var(--tooltip-bg) !important;
    border-radius: 8px !important;
    font-size: $body3 !important;
    font-family: $Poppins !important;
    z-index: 2 !important;
    max-width: 320px !important;

    ul li {
      list-style: disc inside;
    }
  }
}
