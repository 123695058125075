@font-face {
  font-family: 'Poppins Semibold';
  src: url('../../../public/fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('../../../public/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins Regular';
  src: url('../../../public/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins Medium';
  src: url('../../../public/fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins Light';
  src: url('../../../public/fonts/Poppins-Light.ttf');
}
