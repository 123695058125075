@import 'assets/scss/variables';
@import 'assets/scss/utils';

.card {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid var(--border-color);

  @media (max-width: $screen-sm-max) {
    flex-direction: column;
  }

  &--active {
    border: 1px solid $purple50;
    background-color: rgba(#eed9ff1a, 0.1);
  }

  &__dnd {
    @media (max-width: $screen-md-max) {
      flex-direction: column;
      display: none;
    }
    svg {
      width: 20px;
      height: 20px;
      fill: var(--text-primary);
    }
  }

  &__img-wrapper {
    position: relative;
    width: 192px;
    height: 108px;
    border-radius: 8px;

    @media (max-width: $screen-md-max) {
      height: 100%;
      width: 50%;
      margin-bottom: auto;
    }

    @media (max-width: $screen-sm-max) {
      height: 100%;
      width: 100%;
    }

    .badge-wrapper {
      position: absolute;
      top: 4px;
      left: 4px;
      z-index: 1;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;

      &.disabled {
        filter: grayscale(1);
      }
    }

    &--empty {
      background: var(--banner-bg);
      display: flex;
      align-items: center;
      justify-content: center;

      .empty-state {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--content-color);

        svg {
          width: 16px;
          height: 16px;
        }
      }

      svg {
        fill: var(--banner-svg);
        width: 30%;
        height: 30%;
      }
    }

    .card__img__action-overlay {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      opacity: 0;
      cursor: pointer;
      transition: opacity 150ms ease-out;
      svg {
        fill: var(--accent);
        width: 30px;
        height: 30px;
      }

      &--enabled {
        opacity: 1;
        cursor: auto;
      }
    }

    &:hover .card__img__action-overlay {
      opacity: 1;
    }
  }

  &__content {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--top {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    &--bottom {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;

      button {
        text-wrap: nowrap;

        @media (max-width: $screen-md-max) {
          padding: 0;
        }
      }

      @media (max-width: $screen-md-max) {
        flex-direction: column;
        align-items: start;
        margin-top: 12px;
      }
    }

    &--progress {
      width: 50%;

      @media (max-width: $screen-lg-max) {
        width: 100%;
      }
    }
  }

  &__title {
    @include fontBody1;
    font-family: $PoppinsSemibold, sans-serif;
    color: var(--text-primary);
  }

  &__text--primary {
    @include fontBody2;
    font-family: $Poppins, sans-serif;
    color: var(--text-primary);
  }

  &__text--secondary {
    display: flex;
    align-items: center;
    gap: 8px;
    @include fontBody2;
    font-family: $Poppins, sans-serif;
    color: var(--content-color);

    @media (max-width: $screen-lg-max) {
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
    }
  }
}
