@import 'assets/scss/variables';
@import 'assets/scss/utils';

.course {
  gap: 40px;
  padding: 24px 0px;
  display: flex;
  flex-direction: column;

  &__breadcrumb-wrapper {
    gap: 24px;
    width: 100%;
    height: 30px;
    display: flex;

    &__label {
      width: 96px;
      height: 18px;
      border-radius: 4px;
      background-color: var(--banner-bg);
    }
  }

  &__data {
    gap: 72px;
    display: flex;
    transition: border 150ms ease-out;
  
    @media (max-width: $screen-lg-max) {
      flex-direction: column;
    }
  
    &__img-wrapper {
      flex: 2;
      width: 100%;
      min-height: 480px;
      border-radius: 12px;
      background-color: var(--banner-bg);
  
      @media (max-width: $screen-lg-max) {
        min-height: 380px;
      }

      @media (max-width: $screen-sm-max) {
        min-height: 190px;
      }
    }
  
    &__content {
      flex: 1;
      gap: 24px;
      width: 100%;
      display: flex;
      flex-direction: column;
  
      div {
        border-radius: 4px;
        background-color: var(--banner-bg);
      }
  
      &__container {
        gap: 8px;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: unset !important;
      }
  
      &__name {
        width: 80%;
        height: 35px;
      }
  
      &__category {
        width: 84px;
        height: 18px;
      }
  
      &__label {
        width: 84px;
        height: 15px;
      }
  
      &__description {
        width: 80%;
        height: 50px;
      }
  
      &__badge {
        width: 50%;
        height: 18px;
      }
      
      &__rating {
        width: 50%;
        height: 24px;
      }
  
      &__price {
        width: 30%;
        height: 18px;
      }
  
      &__limit {
        width: 50%;
        height: 18px;
      }
  
      &__button {
        width: 100%;
        height: 48px;
      }
    }
  }

  &__tabs-wrapper {
    gap: 36px;
    width: 100%;
    height: 48px;
    display: flex;
    border-bottom: 1px solid var(--border-color);

    &__label {
      width: 96px;
      height: 18px;
      border-radius: 4px;
      background-color: var(--banner-bg);
    }
  }

}
