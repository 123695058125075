@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.admin-report {
  &__table-wrapper {
    gap: 24px;
    width: 100%;
    display: flex;

    @media (max-width: $screen-md-max) {
      gap: 16px;
      flex-direction: column;
    }
  }

  &__actions-wrapper {
    min-width: 250px;
  }

  &__no-results {
    width: 100%;
  }

  &__cards-container {
    flex: 2;
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: $screen-xxl-max) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $screen-lg-max) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__card {
    gap: 16px;
    display: flex;
    height: 200px;
    padding: 32px;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--info-box);

    .loader {
      width: unset;
    }

    &__value {
      font-family: $PoppinsSemibold;
      height: 64px;
    }
  }
}
