@import './assets/scss/utils';

.app__wrapper {
  background-color: var(--app-bg);
  height: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  transition: background-color 150ms ease-out;
}

[data-theme='light'] {
  --app-bg: #{$white10};
  --content-color: #{$black30};
  --content-color-inactive: #{$white60};
  --text-primary: #{$black100};
  --text-primary-inverted: #{$white10};
  --text-primary-inactive: #{$white60};
  --scrollbar-bg: #{$white40};
  --border-color: #{$white50};
  --border-color-active: #{$white70};
  --tooltip-bg: #{$darkBlue80};
  --info-box: #{$lightBlue40};
  --accent: #{$purple50};
  --step: #{$white30};
  --step-hover: #{$white50};
  --step-completed: #{$black90};
  --step-disabled: #{$white30};
  --secondary-bg: #{$white20};
  --overlay: #{rgba($white20, 0.85)};
  --input-border-color: #{$white50};
  --input-border-color-hover: #{$white70};
  --input-border-color-active: #{$black50};
  --input-border-color-disabled: #{$white50};
  --input-text: #{$black30};
  --input-text-active: #{$black100};
  --input-text-disabled: #{$white60};
  --banner-bg: #{$white30};
  --banner-svg: #{$white60};
  --main-shadow: 0px 0px 14px 0px #00000014;
  --secondary-shadow: 0px 0px 28px 0px #00000014;
  --wrapper-shadow: 0px 0px 32px rgba(8, 9, 24, 0.35);
}

[data-theme='dark'] {
  --app-bg: #{$black100};
  --content-color: #{$white90};
  --content-color-inactive: #{$black40};
  --text-primary: #{$white10};
  --text-primary-inverted: #{$black100};
  --text-primary-inactive: #{$black40};
  --scrollbar-bg: #{$black70};
  --border-color: #{$black70};
  --border-color-active: #{$black50};
  --tooltip-bg: #{$lightBlue40};
  --info-box: #{$darkBlue80};
  --accent: #{$purple40};
  --step: #{$black80};
  --step-hover: #{$black60};
  --step-completed: #{$white20};
  --step-disabled: #{$black80};
  --secondary-bg: #{$black90};
  --overlay: #{rgba($black90, 0.85)};
  --input-border-color: #{$black70};
  --input-border-color-hover: #{$black50};
  --input-border-color-active: #{$black40};
  --input-border-color-disabled: #{$black70};
  --input-text: #{$white90};
  --input-text-active: #{$white10};
  --input-text-disabled: #{$black40};
  --banner-bg: #{$black80};
  --banner-svg: #{$black40};
  --main-shadow: 0px 0px 14px 0px #00000014;
  --secondary-shadow: 0px 0px 28px 0px #00000014;
  --wrapper-shadow: 0px 0px 32px rgba(8, 9, 24, 0.35);
}
