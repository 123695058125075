@import "assets/scss/utils";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 60%;

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
    position: relative;

    button {
      position: absolute;
      top: -6px;
      left: -6px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
  }

  .options {
    display: flex;
    justify-content: space-between;

    p {
      text-wrap: nowrap;
    }
  }

  @media (max-width: $screen-md-max) {
    width: 100%;
  }
}
