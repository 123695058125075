@import 'assets/scss/utils';
@import 'assets/scss/variables';

.draft-msg {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 12px;
  background: var(--info-box);

  > div:first-child {
    display: flex;
    align-items: center;
    gap: 12px;
    svg {
      flex-shrink: 0;
    }
  }
}
