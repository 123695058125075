@import 'assets/scss/variables';
@import 'assets/scss/utils';

.badge {
  z-index: 1;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: fit-content;
  text-transform: capitalize;
  font-family: 'Poppins Bold', sans-serif;
  color: $lightBlue10;
  max-height: 24px;
  text-wrap: nowrap;

  svg {
    fill: $white10;
  }
  transition:
    background-color 150ms ease-out,
    border 150ms ease-out;

  &--info {
    background-color: $darkBlue10;
  }

  &--warning {
    background-color: $warning40;
  }

  &--success {
    background-color: $success30;
  }

  &--error {
    background-color: $error40;
  }

  &--primary {
    color: var(--text-primary-inverted) !important;
    background: var(--text-primary);
  }

  &--contrast {
    color: $lightBlue10;
    background-color: $purple50;

    svg {
      fill: $white10;
    }
  }

  &--disabled {
    background-color: var(--content-color-inactive);
  }
  // styles for kyc status badge
  &--status {
    width: 150px;
    height: 18px;
    border-radius: 12px;
    font-size: 12px !important;

    svg {
      width: 12px !important;
      height: 12px !important;
    }
  }

  &--outline {
    color: var(--text-primary);
    background-color: transparent;
    border-color: var(--border-color);
    border-width: 1px;
    border-style: solid;

    svg {
      fill: var(--text-primary);
    }
  }

  &--lg {
    padding: 2px 4px;
    @include fontBody2;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &--sm {
    padding: 1px 4px;
    @include fontBody3;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}
