@import 'assets/scss/utils.scss';

.page-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .components-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 16px;
    max-width: 600px;
  }
}
