@import 'assets/scss/utils';
@import 'assets/scss/variables';

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 768px;
  overflow: scroll;
  position: relative;

  @media (max-width: $screen-lg-max) {
    height: unset;
  }

  &__wrapper {
    height: 768px;
    position: relative;
    margin-left: 32px;

    @media (max-width: $screen-lg-max) {
      height: unset;
      width: 100%;
      margin-left: 0;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .card {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px;
    border-radius: 16px;
    border: 1px solid var(--border-color);
    cursor: pointer;
    width: 100%;
    max-width: 530px;

    @media (max-width: $screen-lg-max) {
      max-width: unset;
    }

    &--active {
      border: 1px solid $purple50;
      background-color: rgba(#eed9ff1a, 0.1);
    }

    &__img-wrapper {
      position: relative;
      width: 192px;
      height: 108px;
      border-radius: 8px;

      @media (max-width: $screen-sm-max) {
        width: 50%;
      }

      .badge-wrapper {
        position: absolute;
        top: 4px;
        left: 4px;
        z-index: 1;
      }

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;

        &.disabled {
          filter: grayscale(1);
        }
      }

      &--empty {
        background: var(--banner-bg);
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: var(--banner-svg);
          width: 30%;
          height: 30%;
        }
      }

      .card__img__action-overlay {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        opacity: 0;
        cursor: pointer;
        transition: opacity 150ms ease-out;
        svg {
          fill: var(--accent);
          width: 30px;
          height: 30px;
        }

        &--enabled {
          opacity: 1;
          cursor: auto;
        }
      }

      &:hover .card__img__action-overlay {
        opacity: 1;
      }
    }

    &__content {
      align-self: stretch;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: $screen-sm-max) {
        width: 50%;
      }

      &--top {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
    }

    &__title {
      @include fontBody1;
      font-family: $PoppinsSemibold, sans-serif;
      color: var(--text-primary);
    }

    &__text--secondary {
      display: flex;
      align-items: center;
      gap: 8px;
      @include fontBody2;
      font-family: $Poppins, sans-serif;
      color: var(--content-color);

      @media (max-width: $screen-lg-max) {
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
      }
    }
  }
}
