@import 'assets/scss/variables';
@import 'assets/scss/fonts';
@import 'assets/scss/utils';

.wrapper {
  position: relative;
  h5 {
    margin-bottom: 4px;
  }
  p {
    margin-bottom: 16px;
  }

  button {
    position: absolute;
    top: 0;
    left: -12px;
  }
}
