@import 'assets/scss/variables';
@import 'assets/scss/utils';
@import 'assets/scss/general';
@import 'assets/scss/mixins';

.label {
  &__wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 4px;

    &--disabled {
      .label__title {
        color: var(--text-primary-inactive) !important;
      }

      .label__tooltip {
        fill: var(--text-primary-inactive);
        cursor: default;
      }

      .label__subtitle {
        color: var(--content-color-inactive);
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    color: var(--text-primary);
    font-family: $PoppinsSemibold;
    @include fontBody3;
  }

  &__tooltip {
    fill: var(--icon-fill);
    width: 16px;
    height: 16px;
  }

  &__subtitle {
    color: var(--content-color);
    font-family: $Poppins;
    @include fontBody3;
  }
}

[data-theme='light'] {
  --icon-fill: #{$black30};
}
[data-theme='dark'] {
  --icon-fill: #{$white90};
}
