@import 'assets/scss/variables';
@import 'assets/scss/utils';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 320px;
    margin-top: 64px;
    @media (max-width: $screen-md-max) {
      width: 240px;
    }
    @media (max-width: $screen-sm-max) {
      width: 160px;
    }
  }
  h4 {
    font-family: $PoppinsSemibold;
    margin-top: 24px;
    margin-bottom: 32px;
    text-align: center;
  }

  p {
    margin-bottom: 32px;
    text-align: center;
    @media (max-width: $screen-md-max) {
      margin-bottom: 64px;
    }
    @media (max-width: $screen-sm-max) {
      margin-bottom: 32px;
    }
  }

  button {
    align-self: flex-end;
    @media (max-width: $screen-sm-max) {
      width: 100%;
    }
  }
}
