@import 'assets/scss/mixins';
@import 'assets/scss/utils';

.container {
  display: flex;
  align-items: center;
  gap: 16px;
  @include fontBody3;
  cursor: pointer;
  border-radius: 12px;

  .course {
    display: flex;
    flex-direction: column;
    gap: 4px;
    > div:last-child {
      font-family: $Poppins;
      color: var(--content-color);
    }
  }

  .thumb {
    height: 92px;
    border-radius: 12px;
    object-fit: cover;
    &--sm {
      height: 60px;
      width: 92px;
    }
    &--lg {
      height: 92px;
      width: 146px;
    }
  }
}
