@import 'assets/scss/variables';
@import 'assets/scss/utils';

.card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  border-radius: 16px;
  border: 1px solid var(--border-color);
  transition: border 150ms ease-out;

  &__img-wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 1.33 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    overflow: hidden;

    .icons-container {
      position: absolute;
      right: 8px;
      top: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      z-index: 1;
    }

    .changes-label {
      z-index: 1;
      border-radius: 4px;
      padding: 1px 4px;
      background-color: $warning40 !important;
      @include fontBody3;
      font-family: $PoppinsSemibold;
      position: absolute;
      right: 8px;
      bottom: 8px;
    }

    > div {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    > button {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--empty {
      background: var(--banner-bg);

      > svg {
        fill: var(--banner-svg);
        width: 20%;
        height: 20%;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 4px 4px;
    height: 100%;
  }

  &__empty {
    button {
      margin-top: auto;
    }
  }

  &__title {
    @include fontBody1;
    font-family: $PoppinsSemibold, sans-serif;
    color: var(--text-primary);
  }

  &__text {
    @include fontBody2;
    font-family: $Poppins, sans-serif;
    color: var(--content-color);
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 8px;
    @include fontBody1;
    font-family: $Poppins, sans-serif;
    color: var(--text-primary);
    margin-top: auto;

    &--number {
      font-family: $PoppinsBold, sans-serif;
    }
  }

  &--clickable {
    cursor: pointer;
    transition:
      box-shadow 150ms ease-out,
      border 150ms ease-out;

    .card__img-wrapper--gradient {
      top: 0 !important;
      left: 0 !important;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      );
      svg {
        fill: white;
      }
    }

    &:hover {
      box-shadow: var(--main-shadow);
    }

    &:active {
      border: 1px solid var(--border-color-active);
    }
  }

  &--disabled {
    .card__img-wrapper {
      img {
        filter: grayscale(100%);
      }
    }

    .card__title {
      color: var(--text-primary-inactive);
    }

    .card__text {
      color: var(--text-primary-inactive);
    }

    .card__price {
      color: var(--text-primary-inactive);

      img {
        filter: opacity(0.5);
      }
    }
  }
}
