@import 'assets/scss/utils';
@import 'assets/scss/variables';
@import 'assets/scss/general';

.wrapper {
  gap: 8px;
  display: flex;
  margin-top: 16px;
  align-items: center;
  flex-direction: column;

  .subtitle {
    color: var(--content-color);
  }

  .content {
    gap: 10px;
    width: 100%;
    display: flex;
    margin: 16px 0px;
    max-height: 290px;
    overflow-y: scroll;
    flex-direction: column;

    .user-container {
      width: 100%;
      display: flex;
      padding: 16px;
      align-items: center;
      border-radius: 12px;
      border: 1px solid $white70;
      background: var(--secondary-bg);
      justify-content: space-between;
  
      .user-data {
        gap: 16px;
        display: flex;
        align-items: center;
      }
    }
  }

  .btns-container {
    gap: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  h5 {
    text-align: center;
  }
}
