@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.currencies {
  &__wrapper {
    gap: 32px;
    width: 100%;
    margin: 64px auto;
    display: flex;
    max-width: 880px;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $screen-sm-max) {
      margin: 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__field {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 16px 16px 16px 8px;
    justify-content: space-between;
    background-color: var(--secondary-bg);

    @media (max-width: $screen-sm-max) {
      height: unset;
    }

    &__left-col {
      display: flex;
      align-items: center;
      gap: 16px;

      @media (max-width: $screen-sm-max) {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }

      img {
        width: 24px;
        height: 24px;
      }
      svg {
        width: 24px;
        height: 24px;
        fill: var(--text-primary);
      }
    }

    &__token {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    &__name {
      font-family: $PoppinsSemibold;
    }

    &__liquidity {
      font-size: $body3;
      color: var(--content-color);
    }
  }
}
