@import 'assets/scss/general';
@import 'assets/scss/utils';
@import 'assets/scss/variables';

.documents {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    svg {
      fill: var(--text-primary);
    }
  }

  &__video {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      border: 1px solid var(--border-color);
      border-radius: 16px;
      cursor: pointer;
      max-width: 768px;
    }

    &__heading {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &__title {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
    .closed {
      rotate: 180deg;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--border-color);
    border-radius: 16px;
    padding: 16px;

    &__heading {
      &__title {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: $body1;
        font-family: $PoppinsSemibold;
      }
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}
