@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__header {
    gap: 8px;
    margin: auto;
    display: flex;
    max-width: 350px;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h5 {
      color: var(--content-color);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;

    &__user {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      text-align: center;

      &__avatar {
        position: relative;
        .verified__badge {
          position: absolute;
          height: 48px;
          width: 48px;
          top: -12px;
          right: -12px;
          z-index: 100;
        }
      }
    }

    &__pending {
      div {
        div {
          display: flex;
          gap: 8px;

          div {
            width: 20px;
          }
        }
      }
    }
  }
}
