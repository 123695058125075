@import 'assets/scss/variables';
@import 'assets/scss/utils';

.header__buttons__wrapper {
  display: flex;
  gap: 12px;
}

.header__buttons {
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 260px;
  position: relative;

  button:last-child {
    width: 100%;
  }

  &--equal {
    button {
      width: 100%;
    }
  }
}
