@import "assets/scss/variables";

.cover {
  width: 100%;
  height: 320px;
  border-radius: 8px;

  &--empty {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--banner-bg);

    svg {
      fill: var(--banner-svg);
      width: 35%;
      height: 35%;
    }
  }

  @media (max-width: #{$screen-lg-max}) {
    height: 200px;
  }
}
