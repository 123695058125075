@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.refund {
  display: flex;
  width: 100%;

  &--grid {
    display: flex;
    gap: 16px;

    @media (max-width: $screen-md-max) {
      display: block;
      width: 100%;
    }
  }

  &__table-wrapper {
    gap: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
