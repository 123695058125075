@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.about-us-wrapper {
  gap: 60px;
  display: flex;
  padding: 0px 88px;
  flex-direction: column;

  @media (max-width: $screen-xl-max) {
    gap: 48px;
    width: 100%;
    padding: 0px;
  }

  .link-contrast {
    background: transparent;
    color: var(--btn-link-contrast-color);
  }

  .header-items {
    gap: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: $screen-md-max) {
      text-align: center;
    }
  }

  .header {
    margin-bottom: 70px;

    @media (max-width: $screen-xl-max) {
      margin-bottom: 48px;
    }

    @media (max-width: $screen-md-max) {
      text-align: center;
      margin-bottom: 0px;

      h2 {
        font-size: 28px;
        line-height: 40px;
      }
    }
  }

  .welcome-container {
    gap: 158px;
    display: flex;

    @media (max-width: $screen-xl-max) {
      gap: 56px;
      flex-direction: column-reverse;
    }

    .content {
      gap: 17px;
      max-width: 481px;
      display: flex;
      flex-direction: column;

      @media (max-width: $screen-xl-max) {
        width: 100%;
        text-align: center;
        align-items: center;
      }
    }

    .img-wrapper {
      width: 100%;
      text-align: center;

      img {
        max-height: 400px;

        @media (max-width: $screen-xl-max) {
          margin-right: 0;
        }

        @media (max-width: $screen-md-max) {
          width: 100%;
        }
      }
    }
  }

  .features {
    display: flex;
    margin-top: 44px;
    flex-direction: column;

    @media (max-width: $screen-xl-max) {
      h3 {
        font-size: 24px;
        text-align: center;
      }
    }

    .cards {
      display: grid;
      margin-top: 52px;

      @media (min-width: $screen-lg-max) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: $screen-xl-max) {
        grid-template-columns: repeat(4, 1fr);
      }

      .card {
        gap: 12px;
        padding: 24px;
        display: flex;
        border-radius: 8px;
        flex-direction: column;
        background: var(--secondary-bg);

        svg {
          margin-bottom: 60px;
        }
      }
    }
  }

  .vision-wrapper {
    width: 100%;
    display: flex;
    margin-top: 120px;
    margin-bottom: 60px;
    border-radius: 16px;
    background: var(--info-box);
    padding: 54px 0px 47px 77px;

    @media (max-width: $screen-xl-max) {
      padding: 24px;
      margin: 40px 0px;
    }

    .data-container {
      flex: 1;
      gap: 24px;
      display: flex;
      flex-direction: column;

      @media (max-width: $screen-xl-max) {
        text-align: center;
        align-items: center;
      }
    }

    .img-wrapper {
      flex: 1;
      position: relative;

      @media (max-width: $screen-xl-max) {
        display: none;
      }

      img {
        top: -158px;
        position: absolute;
      }
    }
  }

  .values {
    .info-wrapper {
      gap: 56px;
      width: 100%;
      display: grid;
      display: flex;
      margin-top: 52px;
      align-items: center;
      margin-bottom: 40px;
      justify-content: center;

      @media (max-width: $screen-md-max) {
        gap: 24px;
        width: auto;
        flex-direction: column;
      }

      @media (min-width: $screen-sm-max) {
        grid-template-columns: repeat(1, 1fr);
      }

      @media (min-width: $screen-md-max) {
        grid-template-columns: repeat(3, 1fr);
      }

      .info-card {
        gap: 16px;
        display: flex;
        max-width: 360px;
        text-align: center;
        align-items: center;
        flex-direction: column;
      }
    }
  }

  .footer {
    gap: 64px;
    left: 50%;
    width: 100vw;
    padding: 85px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    transform: translateX(-50%);
    background-color: var(--secondary-bg);

    @media (max-width: $screen-lg-max) {
      gap: 40px;
      text-align: center;
      padding: 64px 16px;
      h3 {
        font-size: 24px;
      }
    }

    .footer-cards {
      gap: 24px;
      display: grid;
      max-width: 670px;

      @media (min-width: $screen-sm-max) {
        grid-template-columns: repeat(1, 1fr);
      }

      @media (min-width: $screen-lg-max) {
        grid-template-columns: repeat(2, 1fr);
      }

      .card {
        gap: 24px;
        padding: 24px;
        display: flex;
        align-items: center;
        border-radius: 24px;
        flex-direction: column;
        background-color: var(--app-bg);
        border: 1px solid var(--border-color);

        button {
          width: 100%;
          margin-top: auto;
        }
      }
    }
  }
}
