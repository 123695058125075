@import 'assets/scss/general';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    .swap {
      display: flex;
      flex-direction: column;
      gap: 24px;
      border: 1px solid var(--border-color);
      padding: 16px;
      border-radius: 12px;

      &__container {
        display: flex;
        position: relative;
        justify-content: space-between;

        @media (max-width: $screen-sm-max) {
          flex-direction: column-reverse;
          gap: 24px;
        }

        .amount-input {
          input {
            box-shadow: unset;
            padding: 0;
            @include fontH4;
            pointer-events: none;
          }
        }

        &__value {
          width: 50%;

          h3 {
            color: var(--content-color-inactive);
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        &__controls {
          display: flex;
          flex-direction: column;
          gap: 2px;
          flex-shrink: 0;

          @media (max-width: $screen-sm-max) {
            width: 100%;
          }

          button {
            margin-left: auto;
          }
        }

        .slippage-menu {
          position: absolute;
          top: calc(100% + 12px);
          right: 0;
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 300px;
          padding: 16px;
          box-shadow: var(--wrapper-shadow);
          border-radius: 12px;
          background-color: var(--app-bg);

          &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 4px;
          }

          &__warning {
            display: flex;
            align-items: flex-start;
            gap: 4px;
            color: $warning50;
            svg {
              flex-shrink: 0;
              fill: $warning50;
            }
          }
        }
      }
      &__info {
        display: flex;
        justify-content: space-between;
        color: var(--content-color);

        &__pairs {
          gap: 4px;
          display: flex;
          align-items: center;
        }
      }
    }

    .allowance-info-box {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 4px;
      svg {
        fill: var(--accent);
      }
    }

    &__gift {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      border: 1px solid var(--border-color);
      border-radius: 8px;

      .switch {
        > div {
          font-family: $Poppins;
        }
      }

      &__receivers {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__inputs {
          display: flex;
          flex-direction: column;
          gap: 16px;
          overflow-y: scroll;
          max-height: 170px;
          padding-left: 1px;

          .group {
            align-items: flex-end;

            &--error {
              align-items: center;
            }
          }
        }
      }
    }

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }
  }
}
