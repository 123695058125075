@import 'assets/scss/utils';
@import 'assets/scss/variables';

.menu {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    &__change {
      display: grid;
      grid-template-rows: auto auto auto;

      &__row {
        display: grid;
        grid-template-columns: auto 4fr;
        gap: 24px;
        z-index: 1;

        &__values {
          display: flex;
          flex-direction: column;
          gap: 8px;
          word-break: break-word;
          color: var(--text-primary);
        }

        &__badge {
          display: flex;
          flex-direction: column;
          .divider--after-badge {
            flex: 1;
          }
        }
      }

      .divider {
        margin-left: 32px;
      }
    }
  }
}

.thumb {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
}
