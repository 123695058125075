@import 'assets/scss/general';

.form {
  flex: 1;
  justify-content: space-between;

  .fields-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.info-box-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    color: var(--content-color) !important;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.test-success-rate-wrapper {
  @media (max-width: $screen-md-max) {
    gap: 24px;
    align-items: start;
    flex-direction: column;
  }
}

.question-box__wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  border-radius: 8px;
  background: var(--secondary-bg);
  max-height: 300px;
  overflow-y: scroll;
}

.question-box {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > div:first-child {
    > div:first-child {
      display: block;
    }
  }

  > div {
    > a {
      display: block;
    }
  }
}

.answer-buttons {
  @media (max-width: $screen-md-max) {
    gap: 8px;
    align-items: start;
    flex-direction: column;
  }
}

.answer-box {
  position: relative;

  .icon--marker--wrapper {
    position: absolute;
    top: 12px;
    left: 10px;
    cursor: pointer;
    z-index: 1;
  }

  .icon--marker {
    width: 24px;
    height: 24px;
    fill: var(--content-color);

    &--active {
      fill: $purple50;
    }
  }

  input {
    padding: 12px 40px !important;
  }

  .btn--trash {
    visibility: hidden;
    position: absolute;
    top: 8px;
    right: 4px;
    //transition: visibility 150ms ease-out;
  }

  &:hover {
    .btn--trash {
      visibility: visible;
    }
  }
}
