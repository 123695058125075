@import 'assets/scss/variables';
@import 'assets/scss/utils';

.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 12;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    gap: 24px;
    width: 100%;
    background-color: var(--app-bg);
    box-shadow: var(--main-shadow);
    transition: background-color 150ms ease-out;

    &--simple {
      justify-content: space-between;
    }

    @media (max-width: $screen-lg-max) {
      justify-content: space-between;
      padding: 16px 24px;
    }

    &-mobile {
      justify-content: space-between;
    }

    .logo {
      cursor: pointer;
      @media (max-width: $screen-lg-max) {
        height: 48px;
      }
    }

    .search-wrapper {
      flex: 1;
      position: relative;

      > div {
        max-width: 500px;
      }

      .search-results-wrapper {
        gap: 24px;
        width: 100%;
        display: flex;
        margin-top: 20px;
        position: absolute;
        border-radius: 12px;
        flex-direction: column;
        background: var(--app-bg);
        color: var(--text-primary);
        padding: 16px 16px 24px 16px;
        border: 1px solid var(--border-color);
        box-shadow: var(--secondary-shadow);

        .search-results {
          gap: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .category {
            padding: 12px 0;
          }

          .result {
            gap: 16px;
            display: flex;
            cursor: pointer;
            @include fontBody3;
            align-items: center;
            color: var(--text-primary);
            border-radius: 12px;
          }
        }
      }
    }
    .nav-wrapper {
      display: flex;
      gap: 24px;
      align-items: center;
    }

    .btns-container {
      display: flex;
      gap: 8px;
    }
  }
}
