@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.dnd {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  .header {
    padding-left: 56px;
    padding-right: 56px;
    background-color: var(--banner-bg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-radius: 8px;
    @include fontBody3;
    color: var(--text-primary);

    > div:first-child {
      margin-right: 180px;
    }
  }

  .btn-container {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
}
