@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.popup-container {
  gap: 24px;
  display: flex;
  margin-top: -8px;
  flex-direction: column;

  h4 {
    text-align: center;
  }

  .content {
    gap: 24px;
    display: flex;
    margin: 0px -8px;
    text-align: center;
    align-items: center;
    flex-direction: column;

    h4 {
      color: var(--content-color);
    }

    .image-wrapper {
      gap: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .image-component {
        width: 194px;
        height: 194px;
        display: flex;
        border-radius: 8px;
        background: var(--secondary-bg);

        div {
          width: 64px;
          height: 64px;
          margin: auto;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      h5 {
        text-align: left;
      }
    }
  }

  .buttons-wrapper {
    gap: 16px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
