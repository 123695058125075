@import "assets/scss/general";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 24px 0;

  img {
    width: 128px;
    height: 128px;
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    text-align: center;
  }

  button {
    @media (max-width: $screen-sm-max) {
      width: 100%;
    }
  }

  p {
    text-align: center;
  }
}
