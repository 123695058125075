@import 'assets/scss/utils';
@import 'assets/scss/variables';

.menu {
  position: fixed;
  top: 80px;
  left: calc(100% + 400px);
  width: 400px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 40px;
  box-shadow: var(--main-shadow);
  background-color: var(--app-bg);
  padding: 16px;
  transition: left 300ms ease-in-out;
  z-index: 10;
  height: 100%;
  max-height: calc(100vh - 80px);
  overflow: scroll;

  &--open {
    left: calc(100% - 400px);
    transition: left 300ms ease-in-out;
  }

  @media (max-width: $screen-sm-max) {
    width: 100%;

    &--open {
      left: 0%;
      transition: left 300ms ease-in-out;
    }
  }
}
