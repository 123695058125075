@import 'assets/scss/variables';
@import 'assets/scss/utils';

.card {
  gap: 24px;
  display: flex;
  position: relative;
  border-radius: 16px;
  flex-direction: column;

  .context-wrapper {
    top: 15px;
    z-index: 1;
    right: 15px;
    position: absolute;

    svg {
      width: 20px;
      height: 20px;
    }

    .buttons-wrapper {
      width: 140px;
      display: flex;
      flex-direction: column;
    }
  }

  &--clickable {
    cursor: pointer;
    transition:
      box-shadow 150ms ease-out,
      border 150ms ease-out;
  }

  &__img-wrapper {
    width: 100%;
    overflow: hidden;
    max-height: 280px;
    position: relative;
    border-radius: 12px;
    aspect-ratio: 1.33 / 1;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    :hover {
      transition: 0.5s;
      transform: translateX(5px) scale(1.2);
    }
  }

  &__content {
    gap: 16px;
    display: flex;
    padding: 0 4px 4px;
    flex-direction: column;

    .link-contrast {
      background: transparent;
      color: var(--btn-link-contrast-color);
    }
  }

  &__empty {
    button {
      margin-top: auto;
    }
  }

  &__title {
    @include fontBody1;
    font-family: $PoppinsSemibold, sans-serif;
    color: var(--text-primary);
  }

  &__text {
    @include fontBody2;
    font-family: $Poppins, sans-serif;
    color: var(--content-color);
  }

  &__author {
    gap: 8px;
    display: flex;
    margin-top: auto;
    align-items: center;

    img {
      object-fit: cover;
      border-radius: 50%;
    }

    svg {
      padding: 2px;
      border-radius: 20px;
      fill: var(--banner-svg);
      border: 1px solid var(--banner-svg);
    }

    .author-name {
      color: var(--text-primary);
    }

    &--info {
      display: flex;
      flex-direction: column;
    }
  }
}
