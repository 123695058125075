@import 'assets/scss/utils';
@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.browse-users {
  gap: 40px;
  display: flex;
  flex-direction: column;

  &__section {
    gap: 24px;
    display: flex;
    flex-direction: column;

    .cards {
      display: grid;

      @media (min-width: $screen-md-max) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: $screen-lg-max) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &__button {
      margin-top: 16px;
    }
  }
}
