@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.admin-static-pages {
  padding: 16px 120px 60px 120px;

  @media (max-width: $screen-lg-max) {
    padding: 0px;
  }

  h4 {
    margin-bottom: 36px;

    @media (max-width: $screen-lg-max) {
      margin-bottom: 24px;
    }
  }
}
