@import 'assets/scss/variables';
@import 'assets/scss/fonts';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.user {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 80px;
  overflow: hidden;
  color: var(--content-color);
}

.email {
  word-break: break-all;
}

.menu {
  position: relative;
  &__btn {
    padding: 8px !important;
    width: 40px !important;
    height: 40px !important;
    svg {
      flex-shrink: 0;
    }
  }
  &__dropdown {
    position: absolute;
    top: 100%;
    right: 16px;
  }
}
