@import 'assets/scss/utils';

.player__wrapper {
  &__card {
    position: relative !important;
    top: unset !important;
    left: unset !important;
    height: 100%;
    width: 100%;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      object-fit: cover;
    }
  }

  &--responsive {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    border-radius: 12px;
    overflow: hidden;

    .theatre-btn {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 1;
      svg {
        fill: white;
      }
    }

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__cta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Adjust for the div's own dimensions */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    color: white;
    z-index: 1;

    .play-next-btn {
      width: unset;
      height: unset;
      border-radius: 50%;
      border: 4px solid white;
      svg {
        width: 64px;
        height: 64px;
        fill: white;
      }
    }

    div {
      text-align: center;
    }
  }

  &--theatre {
    border-radius: unset;
  }

  video {
    border-radius: 12px;
  }
}
