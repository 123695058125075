@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.blog-wrapper {
  gap: 36px;
  display: flex;
  padding: 0px 88px;
  flex-direction: column;

  @media (max-width: $screen-lg-max) {
    width: 100%;
    padding: 0px;
  }

  .link-contrast {
    background: transparent;
    color: var(--btn-link-contrast-color);
  }

  .header {
    gap: 8px;
    display: flex;
    margin-bottom: 82px;
    flex-direction: column;

    @media (max-width: $screen-lg-max) {
      margin-bottom: 24px;
    }

    @media (max-width: $screen-sm-max) {
      .title {
        font-size: 28px;
      }
    }
  }

  .content {
    gap: 64px;
    display: flex;

    @media (max-width: $screen-lg-max) {
      flex-direction: column;
    }

    &__search {
      flex: 1;
      gap: 32px;
      display: flex;
      flex-direction: column;

      &__tags {
        gap: 16px;
        display: flex;
        flex-direction: column;

        h5 {
          cursor: pointer;
        }

        .selected {
          color: black;
        }
      }
    }

    &__blog {
      flex: 3;
      gap: 40px;
      display: flex;
      flex-direction: column;
    }
  }
}
