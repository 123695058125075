@import 'assets/scss/general';

.info-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 12px;
  border-radius: 12px;
  background: var(--info-box);

  .info {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 6px;

    &__text {
      display: flex;
      flex-direction: column;
      gap: 4px;
      &__title {
        font-family: $PoppinsSemibold;
        color: var(--text-primary);
      }
    }

    svg {
      width: 20px;
      height: 20px;
      fill: var(--accent);
      flex-shrink: 0;
    }

    &--warning {
      svg {
        fill: $error40;
      }
    }
  }

  > button {
    svg {
      flex-shrink: 0;
    }
  }

  @media (max-width: $screen-md-max) {
    flex-direction: column;
    padding: 12px;
  }
}

.info-box {
  padding: 12px;
  border-radius: 12px;
  background: var(--info-box);
}
