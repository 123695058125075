@import 'assets/scss/variables';
@import 'assets/scss/utils';

.card {
  gap: 16px;
  padding: 8px;
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  border: 1px solid var(--border-color);
  transition: border 150ms ease-out;

  &__img-wrapper {
    &__course {
      height: 192px;
      border-radius: 12px;
      background-color: var(--banner-bg);
    }

    &__creator {
      height: 127px;
      border-radius: 12px;
      background-color: var(--banner-bg);
    }

    &__certificate {
      height: 240px;
      border-radius: 12px;
      background-color: var(--banner-bg);
    }
  }

  &__content {

    &__course {
      gap: 8px;
      display: flex;
      padding: 0 4px 4px;
      flex-direction: column;
    }

    &__certificate {
      gap: 8px;
      display: flex;
      flex-direction: column;
    }

    &__creator {
      gap: 32px;
      display: flex;
      margin-top: -60px;
      padding: 0 8px 8px;
      flex-direction: column;

      &__logo-wrapper {
        gap: 16px;
        display: flex;
        align-items: center;
        flex-direction: column;

        div:first-child {
          width: 96px;
          height: 96px;
          border-radius: 12px;
          align-items: center;
          border: 4px solid var(--app-bg);
          transition: border 150ms ease-out;
          background-color: var(--banner-bg);
        }

        div:last-child {
          width: 130px;
          height: 18px;
          border-radius: 4px;
          background-color: var(--banner-bg);
        }
      }
    }
  }

  &__certificate {
    gap: 4px;
    padding: 0px;
    border: none;
    border-radius: 8px;
  }

  &__badge {
    width: 74px;
    height: 18px;
    border-radius: 4px;
    background-color: var(--banner-bg);
  }

  &__name {
    width: 140px;
    height: 18px;
    border-radius: 4px;
    background-color: var(--banner-bg);
  }

  &__subtitle {
    width: 150px;
    height: 14px;
    border-radius: 4px;
    background-color: var(--banner-bg);
  }

  &__button {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background-color: var(--banner-bg);
  }

  &__title {
    &__creator {
      div {
        width: 100%;
        height: 18px;
        border-radius: 4px;
        background-color: var(--banner-bg);
      }
    }

    &__course {
      display: flex;
      flex-direction: column;
      gap: 2px;

      div:first-child {
        width: 100%;
        height: 18px;
        border-radius: 4px;
        background-color: var(--banner-bg);
      }
      div:last-child {
        width: 75%;
        height: 18px;
        border-radius: 4px;
        background-color: var(--banner-bg);
      }
    }
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 8px;

    div:first-child {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background-color: var(--banner-bg);
    }
    div:last-child {
      width: 96px;
      height: 24px;
      border-radius: 4px;
      background-color: var(--banner-bg);
    }
  }

}
