@import 'assets/scss/variables';
@import 'assets/scss/utils';

.wrapper {
  position: relative;
  background-color: var(--app-bg);
  padding: 83px 56px 48px 56px;
  overflow: hidden;
  width: 100%;
  transition: background-color 150ms;

  @media (max-width: $screen-lg-max) {
    padding: 80px 24px 52px 24px;
  }

  .divider-wrapper {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
  }

  .footer {
    &__top {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      margin-top: 64px;
      padding-bottom: 64px;
      gap: 24px;

      @media (max-width: $screen-lg-max) {
        padding-bottom: 24px;
        grid-template-columns: 1fr;
      }

      &__col-left {
        display: flex;
        width: 100%;
        gap: 48px;

        @media (max-width: $screen-lg-max) {
          flex-direction: column;
          gap: 32px;
        }

        &__links {
          display: grid;
          grid-template-columns: 1fr 1fr;
          justify-content: space-between;
          width: 100%;
          gap: 16px;

          .link {
            margin: 8px 0;
            cursor: pointer;
            color: var(--text-primary);
          }
        }

        .logo {
          display: flex;
          justify-content: space-between;

          img {
            max-height: 56px;
          }
        }
      }

      &__col-right {
        width: 100%;
        color: var(--text-primary);

        .form {
          display: flex;
          gap: 8px;
          width: 100%;
          margin-bottom: 16px;
          &__input {
            width: 100%;
          }

          @media (max-width: $screen-lg-max) {
            flex-direction: column;
          }
        }

        a {
          text-decoration: underline;
          color: var(--content-color);
        }
      }
      .contact {
        margin-top: 32px;
        font-size: $body3;
      }
    }
    &__bottom {
      display: flex;
      padding-top: 32px;
      justify-content: space-between;

      @media (max-width: $screen-lg-max) {
        padding-top: 0px;
      }

      &__terms-and-conditions {
        display: flex;
        align-items: center;
        gap: 32px;

        @media (max-width: $screen-lg-max) {
          gap: 24px;
          padding-top: 24px;
        }

        a {
          text-decoration: underline;
          color: var(--content-color);

          @media (max-width: $screen-sm-max) {
            text-align: center;
          }
        }
      }

      &__socials {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        flex-wrap: wrap;
        button {
          height: unset;
          padding: unset;
          width: unset;
        }
      }
    }
  }
}
