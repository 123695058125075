@import 'assets/scss/utils';
@import 'assets/scss/variables';
@import 'assets/scss/general';

.wrapper {
  gap: 8px;
  display: flex;
  margin-top: 16px;
  align-items: center;
  flex-direction: column;

  .subtitle {
    color: var(--content-color);
  }

  .content {
    gap: 12px;
    width: 100%;
    padding: 16px;
    display: flex;
    border-radius: 8px;
    margin-bottom: 16px;
    flex-direction: column;
    background: var(--secondary-bg);
    justify-content: space-between;

    .course-price-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label-container {
        gap: 2px;
        display: flex;
        align-items: center;
      }
    }
  }

  .btns-container {
    gap: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  h5 {
    text-align: center;
  }
}
