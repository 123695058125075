@import 'assets/scss/utils';

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 48px 0;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    width: $screen-lg-max;

    @media (max-width: $screen-lg-max) {
      width: 100%;
    }
  }
}
