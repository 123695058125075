@import 'assets/scss/utils';

.swiper {
  width: 100%;

  :global(.swiper-pagination-bullet) {
    opacity: 1;
    width: 12px;
    height: 12px;
    background: var(--content-color-inactive);
  }

  :global(.swiper-pagination-bullet-active) {
    width: 12px;
    height: 12px;
    background-color: $purple50;
  }
}

.section__hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: $white20;

  @media (max-width: $screen-lg-max) {
    gap: 48px
  }

  h2,
  h3 {
    line-height: 48px;
  }

  @media (max-width: $screen-lg-max) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @media (max-width: $screen-sm-max) {
    max-width: 100%;
    overflow: hidden;
    justify-content: center;
  }

  &__info-col {
    padding: 64px 0 64px 64px;

    @media (max-width: $screen-lg-max) {
      padding: 47px 24px 0px 24px;
    }

    h1 {
      margin-bottom: 16px;
      color: $black100;
    }

    &__subtitle {
      color: $black30;
      margin-bottom: 48px;
    }

    button {
      @media (max-width: $screen-lg-max) {
        width: 100%;
      }
    }
  }

  &__img-col {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    .student-img {
      position: relative;
      z-index: 2;
      max-width: 632px;
    }
  }
}
