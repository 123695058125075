@import 'assets/scss/variables';
@import 'assets/scss/fonts';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.wrapper {
  position: fixed;
  bottom: 64px;
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 100%;

  .island {
    position: relative;
    width: calc(100% - 48px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--info-box);
    border-radius: 12px;
    padding: 18px 24px;

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
      gap: 8px;
    }
  }
}
