@import 'assets/scss/utils';
@import 'assets/scss/variables';

.popup-container {
  gap: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .content {
    gap: 8px;
    display: flex;
    align-items: center;

    &__title-wrapper {
      gap: 4px;
      display: flex;
      align-items: start;
      flex-direction: column;
      color: var(--text-primary);
    }

    &__subtitle-container {
      gap: 8px;
      display: flex;
      align-items: center;
      color: var(--content-color);
    }
  }
  .exam {
    gap: 8px;
    display: flex;
    flex-direction: column;

    &__title {
      border-radius: 8px;
      padding: 14px 16px;
      font-weight: 600;
      border: 1px solid $white40;
      color: var(--text-primary);
    }

    &__input-wrapper {
      div {
        gap: 8px;

        label {
          border-radius: 8px;
          padding: 14px 16px;
          border: 1px solid $white40;
        }
      }
    }
    &__inactive {
      pointer-events: none;
    }
  }
  .progress-wrapper {
    gap: 8px;
    display: flex;
    align-items: center;
    color: var(--text-primary);

    .progress {
      width: 100%;
      div + div {
        display: none;
      }
    }
  }

  .status-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 24px 0px 24px 24px;

    &--success {
      border: 1px solid $success40;
      background-color: var(--success-contrast-bg);
    }
    &--failed {
      border: 1px solid $error40;
      background-color: var(--error-contrast-bg);
    }

    &__icon-and-status {
      gap: 12px;
      display: flex;
      align-items: center;
    }

    &__status {
      display: flex;
      flex-direction: column;
      color: var(--text-primary);

      &--success {
        color: $success40;
      }
      &--failed {
        color: $error40;
      }
    }
  }

  .summary {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
      gap: 8px;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__title {
      color: var(--content-color);
    }

    &__score {
      &--success {
        color: $success40;
      }

      &--error {
        color: $error40;
      }
    }
  }

  .btns-container {
    display: flex;
    justify-content: space-between;

    &__right-button {
      margin-left: auto;
    }
  }
}

[data-theme='light'] {
  --error-contrast-bg: #fcf7f9;
  --success-contrast-bg: #f3faf6;
}

[data-theme='dark'] {
  --error-contrast-bg: #a401280d;
  --success-contrast-bg: #0da0480d;
}
