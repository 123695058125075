@import 'assets/scss/variables';
@import 'assets/scss/utils';

.popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .content {
    gap: 8px;
    display: flex;
    flex-direction: column;
    word-break: break-word;
    text-align: center;
  }

  &__btns-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .icon {
    margin-top: 48px;

    svg {
      fill: $purple40;
    }
  }
}
