@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.wrapper {
  display: flex;
  flex-direction: column;

  &__empty-space {
    gap: 24px;

    @media (max-width: $screen-md-max) {
      gap: 8px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: $screen-md-max) {
      gap: 8px;
    }

    > div:first-child {
      flex: 1;
    }

    &__inverted {
      .search {
        width: 100%;
      }
    }

    &__sort {
      width: 100%;
    }

    &__mobile {
      gap: 8px;
      width: 100%;
      display: flex;

      .input__group {
        width: 100%;
      }
    }

    @media (max-width: $screen-md-max) {
      flex-direction: column;

      > div {
        width: 100%;
      }

      > button {
        width: 100%;
      }
    }
  }
}
