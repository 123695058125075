@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.form-wrapper {
  gap: 64px;

  div:first-child {
    max-width: 656px;
  }

  .form {
    width: 100%;

    h4:first-child {
      display: none;
    }
  }
}

.blog-form-step {
  gap: 40px;
  width: 650px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: $screen-md-max) {
    width: auto;
    margin: initial;
  }
  h5 {
    margin-bottom: 4px;
  }

  .row-card {
    display: flex;
    align-items: center;
    flex-direction: column;

    div {
      width: 100%;
    }

    svg {
      z-index: 1;
      margin-top: -20px;
      margin-bottom: -20px;
    }
  }
}

.tags-wrapper {
  gap: 16px;
  display: flex;
  flex-direction: column;

  .input-wrapper {
    display: flex;
    align-items: center;

    div:first-child {
      width: 100%;
    }

    button {
      z-index: 1;
      margin-left: -62px;
    }
  }

  .categories-wrapper {
    gap: 8px;
    display: flex;
    flex-wrap: wrap-reverse;

    button {
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      border-radius: 16px;
      background-color: var(--secondary-bg);

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .tag {
    button {
      background-color: $purple10;
    }

    button:hover {
      background-color: $purple20;
    }
  }
}

.course-bundle {
  display: flex;
  width: 100%;

  &--grid {
    display: flex;
    gap: 16px;

    @media (max-width: $screen-md-max) {
      display: block;
      width: 100%;
    }
  }

  &__table-wrapper {
    gap: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__action-btns {
    display: flex;
    gap: 8px;
    margin-left: 8px;

    .active {
      background-color: var(--btn-neutral-bg--active) !important;
    }
  }
}
