@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.categories-wrapper {
  gap: 32px;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 880px;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $screen-sm-max) {
    gap: 16px;
  }

  .categories-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $screen-sm-max) {
      gap: 16px;
      align-items: unset;
      flex-direction: column;
    }

    @media (min-width: $screen-sm-max) {
      .search-container {
        width: 387px;
      }
    }
  }

  .category-fields-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
