@import 'assets/scss/variables';
@import 'assets/scss/fonts';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.status {
  gap: 4px;
  display: flex;
  padding: 2px 8px;
  width: fit-content;
  border-radius: 8px;
  align-items: center;

  h5 {
    color: white;
  }

  &__approved {
    background: $success40;
  }

  &__rejected {
    background: $error40;
  }

  svg {
    fill: white;
  }
}
