@import 'assets/scss/variables';
@import 'assets/scss/utils';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .card-row {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: $screen-lg-max) {
      gap: 16px;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        text-decoration: none;
      }

      @media (max-width: $screen-lg-max) {
        padding-right: 24px;
      }
    }

    &__row-container {
      gap: 16px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      // Safari
      height: fit-content;

      @media (max-width: $screen-xxl-max) {
        overflow: scroll;
        scrollbar-width: none;
        grid-auto-flow: column;
        -ms-overflow-style: none;
        grid-auto-columns: 320px;
        grid-template-columns: repeat(4, 320px);
      }

      &--empty {
        grid-template-columns: 1fr;
      }
    }

    &__row-container::-webkit-scrollbar {
      @media (max-width: $screen-xxl-max) {
        display: none;
      }
    }
  }
}
