@import 'assets/scss/variables';
@import 'assets/scss/utils';

.card {
  position: relative;
  display: flex;
  gap: 8px;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 16px;

  .close-btn {
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 8px !important;
    height: 40px;
    width: 40px;
  }

  &__thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 192px;
    max-width: 304px;
    width: 100%;
    border-radius: 12px;

    svg {
      display: none;
    }

    &:hover {
      .play-icon {
        fill: $purple50;
        display: block !important;
      }
    }

    @media (max-width: $screen-md-max) {
      max-width: unset;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    padding: 8px;

    &__details {
      gap: 8px;
      display: flex;
      flex-direction: column;
      color: var(--text-primary);
    }
  }

  @media (max-width: $screen-md-max) {
    flex-direction: column;
  }
}
