@import 'assets/scss/variables';
@import 'assets/scss/utils';

.page {
  display: flex;
  flex-direction: column;
  gap: 24px;
  &__header {
    text-align: center;
    margin-bottom: 24px;
  }
  &__content {
    gap: 8px;
    display: flex;
    max-height: 450px;
    flex-direction: column;

    .rsw-ce {
      overflow-y: scroll;
    }
  }
  &__btns-container {
    display: flex;
    flex-direction: column;
  }
}
