@import 'assets/scss/variables';
@import 'assets/scss/utils';

.progress {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__bar {
    flex: 1;
    height: 8px;
    background-color: var(--border-color);
    border-radius: 8px;
    position: relative;
    transition: background-color 150ms ease-out;

    &--active {
      position: absolute;
      top: 0;
      left: 0;
      height: 8px;
      background-color: $purple50;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      transition: background-color 150ms ease-out;

      &--full {
        border-radius: 8px;
      }
    }
  }

  &__number {
    @include fontBody3;
    font-family: $Poppins, sans-serif;
    color: var(--content-color);
  }
}
