@import 'assets/scss/utils';
@import 'assets/scss/general';

.child-with-indicator {
  display: flex;
  align-items: center;
  gap: 4px;

  &__indicator {
    padding: 1px 4px;
    height: auto;
    background-color: $warning40 !important;
    @include fontBody3;
    svg {
      height: 16px;
      width: 16px;
    }

    &--new {
      background-color: $success30 !important;
    }

    &--deleted {
      background-color: $error30 !important;
    }
  }
}
