@import 'assets/scss/variables';
@import 'assets/scss/utils';

.btn {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  border-radius: 8px;
  color: var(--content-color);
  cursor: pointer;

  &--active {
    background-color: var(--nav-btn-bg);
    color: var(--text-primary);
  }

  &--space-between {
    justify-content: space-between;
  }

  .icon {
    &--primary {
      fill: var(--text-primary);
    }
    &--content {
      fill: var(--content-color);
    }
  }
}

[data-theme='light'] {
  --nav-btn-bg: #{$white40};
}
[data-theme='dark'] {
  --nav-btn-bg: #{$black70};
}
