@import 'assets/scss/variables';
@import 'assets/scss/utils';

.Toastify {
  &__close-button {
    opacity: unset !important;
    svg {
      fill: var(--text-primary) !important;
    }
  }

  .Toastify__toast {
    background: var(--toast-bg);
    padding: 12px !important;
    box-shadow: unset !important;
    border: 1px solid var(--border-color) !important;

    &-container {
      min-width: 400px !important;
    }

    &-body {
      gap: 8px;
      align-items: flex-start !important;
      padding: 0px !important;
      margin: 0 !important;
    }
    // Override variables
    --border-color-light: #{$white50};
    --border-color-black: #{$black70};

    &-icon {
      margin-inline-end: 0 !important;
    }
  }
}
