@import 'assets/scss/general';

.info-box {
  width: 100%;
}

.progress-bar-wrapper {
  gap: 16px;
  display: flex;

  &__labels {
    gap: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.hide-video-preview-wrapper {
  margin-bottom: 16px;
}

.input-wrapper {
  display: flex;
  align-items: center;

  div:first-child {
    width: 100%;
  }

  button {
    z-index: 1;
    margin-top: 23px;
    margin-left: -62px;
  }
}

.skills-wrapper {
  gap: 8px;
  display: flex;
  flex-wrap: wrap-reverse;

  button {
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 16px;
    background-color: var(--secondary-bg);

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.skill {
  border: 1px solid var(--text-primary);
}

.badge-wrapper {
  gap: 12px;
  display: flex;
  cursor: pointer;
  margin-top: -28px;
  align-items: center;

  div {
    border-color: var(--text-primary);
  }
}

.badge-summary-container {
  margin-top: -14px;

  h5 {
    margin-bottom: 4px;
  }

  .badge-wrapper-summary {
    gap: 12px;
    display: flex;
    cursor: pointer;
    align-items: center;

    div {
      border-color: var(--text-primary);
    }
  }
}
