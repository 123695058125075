@import 'assets/scss/variables';
@import 'assets/scss/utils';

.verified {
  display: flex;
  align-items: center;

  gap: 4px;

  @media (max-width: $screen-lg-max) {
    justify-content: center;
  }
}
