@import 'assets/scss/utils';
@import 'assets/scss/general';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__main {
    display: grid;
    grid-template-columns: 1fr auto;

    @media (max-width: $screen-lg-max) {
      display: block;
    }

    .tabs-wrapper {
      thead {
        display: none;
      }
    }
  }

  &__content {
    gap: 40px;
    display: flex;
    position: relative;
    flex-direction: column;

    &__img-wrapper {
      flex: 2;
      position: relative;

      &--empty {
        width: 100%;
        height: auto;
        background: var(--banner-bg);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: var(--banner-svg);
          width: 20%;
          height: 20%;
        }
      }

      img {
        border-radius: 12px;
        width: 100%;
        height: auto;
      }
    }

    .bundle-data {
      display: flex;
      gap: 72px;

      @media (max-width: $screen-lg-max) {
        flex-direction: column;
        gap: 36px;

        &__img-wrapper {
          img {
            width: 100%;
          }
        }
      }

      .content {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .title {
          @include fontBody3;
          color: var(--text-primary);
          font-family: $PoppinsSemibold, sans-serif;
        }

        &__created-by {
          gap: 4px;
          display: flex;
          align-items: center;
        }

        &__row {
          gap: 4px;
          display: flex;
          flex-direction: column;
        }

        &__details {
          gap: 4px;
          display: flex;
          flex-direction: column;
          color: var(--content-color);
          @include fontBody2;
          font-family: $Poppins, sans-serif;

          &__price {
            display: flex;
            align-items: center;
            gap: 8px;
            @include fontBody1;
            font-family: $Poppins, sans-serif;
            color: var(--text-primary);

            &--number {
              font-family: $Poppins, sans-serif;
            }
          }
        }
      }
    }
  }
}
