@import 'assets/scss/variables';
@import 'assets/scss/utils';

.wrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 24px;
    height: 24px;
  }

  .rating {
    &__value {
      color: var(--text-primary);
      @include fontBody2();
      font-family: 'Poppins SemiBold', sans-serif;
    }

    &__count-per-rating {
      color: var(--content-color);
      @include fontBody3();
      font-family: 'Poppins Regular', sans-serif;
      width: 20px;
      text-align: center;
    }

    &__total-count {
      color: var(--content-color);
      @include fontBody3();
      font-family: 'Poppins Regular', sans-serif;
    }
  }
}
