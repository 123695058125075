@import 'assets/scss/utils';
@import 'assets/scss/general';

.reviews {
  &__container {
    display: grid;
    grid-template-columns: 3fr 432px;
    justify-content: space-between;
    gap: 128px;

    @media (max-width: $screen-lg-max) {
      grid-template-columns: 3fr 300px;
      gap: 64px;
    }
    @media (max-width: $screen-md-max) {
      display: flex;
      flex-direction: column-reverse;
      gap: 32px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: var(--text-primary);

    &__sort-wrapper {
      display: flex;
    }

    &__item {
      &__wrapper {
        position: relative;
        display: flex;
        align-items: flex-start;
        gap: 16px;
        padding: 24px 0;
        border-bottom: 1px solid var(--border-color);

        .visibility-btn {
          position: absolute;
          top: calc(50% - 24px);
          right: 0px;
          display: flex;
          align-items: center;
          gap: 8px;
          margin-left: auto;
          button {
            width: 24px;
          }
        }
      }

      &__container {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &__heading {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      &__username {
        color: $purple50;
        width: unset;
        div {
          justify-content: flex-start;
          color: $purple50;
          text-decoration: underline;
        }
      }

      &__rating {
        display: flex;
        align-items: center;
        gap: 8px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &__summary {
    &__wrapper {
    }

    &__container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      border-radius: 16px;
      border: 1px solid var(--border-color);
    }

    &__heading {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__ratings {
      .bar {
        &__wrapper {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        &__container {
          position: relative;
          background-color: var(--border-color);
          width: 100%;
          height: 8px;
          border-radius: 12px;
        }
        &__fill {
          position: absolute;
          left: 0;
          top: 0;
          background-color: $purple50;
          height: 8px;
          border-radius: 12px;
        }
      }
    }
  }
}
