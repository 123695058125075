@import 'assets/scss/utils';
@import 'assets/scss/variables';

.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
  gap: 48px;

  @media (max-width: $screen-xxl-max) {
    padding-top: 0px;
  }

  &__cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    @media (max-width: $screen-lg-max) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $screen-sm-max) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-radius: 16px;
    background-color: var(--info-box);
    padding: 32px;

    .loader {
      width: unset;
    }

    &__value {
      font-family: $PoppinsSemibold;
      height: 64px;
    }

    &__change {
      display: flex;
      gap: 4px;
      height: 21px;
      color: var(--text-primary);
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: var(--content-color);

    &__heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h5 {
        color: inherit;
      }
      a {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}
