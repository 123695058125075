@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.admin-tax-report {
  gap: 16px;
  width: 100%;
  display: flex;

  &__actions-wrapper {
    min-width: 250px;
  }

  &__tables-wrapper {
    width: 100%;
    overflow: scroll;

    &__states-loader {
      margin-top: 24px;
    }

    @media (max-width: $screen-lg-max) {
      gap: 16px;
      display: flex;
      flex-direction: column;
    }
  }

  &__no-results {
    width: 100%;
  }

  @media (max-width: $screen-md-max) {
    flex-direction: column;
  }
}
