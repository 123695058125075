@import 'assets/scss/utils';

.single {
  background-color: var(--secondary-bg);
  width: 100%;
  height: 100%;
  padding: 8px;
  border-radius: 16px;
  max-width: 880px;
  margin: 0 auto;
  color: var(--text-primary);
  transition: background-color 150ms;

  &__container {
    display: flex;
    flex-direction: column;
    background-color: var(--app-bg);
    padding: 48px;
    border-radius: 8px;
    gap: 48px;

    @media (max-width: $screen-md-max) {
      padding: 16px 36px;
    }

    &__section {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      h4 {
        font-family: $PoppinsSemibold;
      }

      ul {
        li {
          list-style-type: circle;
        }
      }
    }
  }
}
