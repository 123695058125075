@import 'assets/scss/utils';
@import 'assets/scss/general';

.feedback {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    h4 {
      padding-top: 52px;
      font-family: $PoppinsSemibold;
      text-align: center;
    }

    textarea {
      resize: none !important;
    }
  }

  &__rate {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__btns-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
