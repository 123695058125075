@import 'assets/scss/utils';
@import 'assets/scss/variables';
@import 'assets/scss/general';

.landing {
  gap: 64px;
  display: flex;
  padding-top: 64px;
  flex-direction: column;

  @media (max-width: $screen-lg-max) {
    gap: 40px;
  }

  @media (max-width: $screen-lg-max) {
    padding-top: 0px;
  }

  .section {
    @media (max-width: $screen-xxl-max) {
      padding: 0 56px;
    }

    &--no-side-padding {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &--highlighted-section-mobile {
      height: 550px;
    }

    @media (max-width: $screen-lg-max) {
      padding: 0 24px;
    }

    &__continue-watching {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    &__categories {
      padding: 64px 0 80px 0;
      gap: 40px;
      display: flex;
      flex-direction: column;

      @media (max-width: $screen-lg-max) {
        padding: 0;
        margin-bottom: 0px;
      }

      &__body {
        gap: 55px;
        display: flex;

        @media (max-width: $screen-xxl-max) {
          gap: 32px;
          overflow: scroll;
          scrollbar-width: none;
          -ms-overflow-style: none;
        }

        .card {
          gap: 16px;
          display: flex;
          cursor: pointer;
          flex-direction: column;
          color: var(--text-primary);

          &__img-wrapper {
            height: 194px;
            width: 194px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--secondary-bg);
            border-radius: 16px;
            img {
              height: 64px;
              width: 64px;
            }
          }
        }
      }

      &__body::-webkit-scrollbar {
        @media (max-width: $screen-xxl-max) {
          display: none;
        }
      }
    }

    &__bundles {
      gap: 40px;
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .scrollable-section {
    @media (max-width: $screen-lg-max) {
      padding-right: 0px;
    }
  }

  .best-seller-wrapper {
    background-color: var(--info-box);
  }

  .controls {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    margin-top: 16px;
    width: 100%;
    &__dots {
      display: flex;
      gap: 8px;
      &__single {
        cursor: pointer;
        height: 12px;
        width: 12px;
        border-radius: 100%;
        background-color: var(--content-color-inactive);
        &--active {
          background-color: $purple50;
        }
      }
    }
    &__btns-container {
      position: absolute;
      right: 0;
      display: flex;
      gap: 8px;
    }
  }
}
