@import 'assets/scss/variables';
@import 'assets/scss/fonts';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.course {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 92px;
  overflow: hidden;
  color: var(--content-color);

  &__img-container {
    position: relative;
    aspect-ratio: 1.587/1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    overflow: hidden;
    max-width: 146px;
    color: inherit;

    &--empty {
      width: 146px;
      height: 92px;
      background: var(--banner-bg);

      svg {
        fill: var(--banner-svg);
        width: 30%;
        height: 30%;
      }
    }

    > img {
      min-width: 146px;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      object-fit: cover;
    }
  }

  &__name {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
