@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.popup-container {
  gap: 24px;
  display: flex;
  margin-top: -8px;
  flex-direction: column;

  .content {
    gap: 24px;
    display: flex;
    margin: 0px -8px;
    flex-direction: column;

    .img-wrapper {
        .custom-file-upload {
            display: flex;
            justify-content: center;
        }
    }
  }

  .buttons-wrapper {
    gap: 16px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
