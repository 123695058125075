.dnd {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  .btn-container {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
}
