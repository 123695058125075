@import "assets/scss/variables";

.c-divider {
  background-color: var(--border-color);
  transition: background-color 150ms ease-out;

  &--horizontal {
    width: 100%;
    height: 1px;
  }

  &--vertical {
    height: 24px;
    width: 1px;

    &--auto {
      height: auto;
    }
  }
}
