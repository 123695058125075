@import "assets/scss/utils";

.wrapper {
  height: 100%;
  padding: 24px;
  background-size: cover;
  display: flex;
  justify-content: center;

  .container {
    gap: 16px;
    width: 505px;
    padding: 32px;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    > div {
      gap: 16px;
      display: flex;
      flex-direction: column;
    }

    @media (max-width: $screen-md-max) {
      gap: 24px;

      img {
        width: 150px;
      }

      h1 {
        font-size: $h3;
        line-height: $lhH3;
      }

      p {
        font-size: $body2;
      }
    }
  }
}
