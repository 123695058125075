@import 'assets/scss/mixins';
@import 'assets/scss/utils';
@import 'assets/scss/general';

.wrapper {
  position: relative;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 10;
  overflow-y: hidden;
  transition: height 300ms;

  &--open {
    height: calc(100vh - 72px);
    overflow-y: scroll;
    transition: height 300ms;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--app-bg);
    padding: 16px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 0px 14px 0px #0000000f;
    z-index: 10;
    color: var(--text-primary);

    .nav-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .search-popup-container {
    position: fixed;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--app-bg);
    padding: 16px 24px;
    color: var(--text-primary);
    overflow: scroll;

    .header {
      display: flex;
      align-items: center;
      gap: 4px;
      @include fontBody1;
    }

    .search-results-wrapper {
      gap: 24px;
      width: 100%;
      display: flex;
      border-radius: 12px;
      flex-direction: column;
      background: var(--app-bg);
      color: var(--text-primary);
      border: 1px solid var(--border-color);
      padding: 16px 16px 24px 16px;

      .search-results {
        gap: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .category {
          padding: 12px 0;
        }

        .result {
          gap: 16px;
          display: flex;
          @include fontBody3;
          align-items: center;
          color: var(--text-primary);
        }
      }
    }
  }
}
