@import 'assets/scss/utils';
@import 'assets/scss/general';

.island {
  position: sticky;
  margin-left: -24px;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: calc(100% + 48px);
  background-color: var(--app-bg);
  padding: 24px;
  z-index: 1;
  border-top: 1px solid var(--border-color);

  &__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 450px;
  }
}
