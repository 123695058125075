@import 'assets/scss/utils';
@import 'assets/scss/general';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__outer {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__inner {
    display: grid;
    grid-template-columns: 1fr auto;

    @media (max-width: $screen-lg-max) {
      display: block;
    }
  }

  .main {
    display: flex;
    gap: 72px;

    &--theatre-mode {
      flex-direction: column;
    }

    &__img-wrapper {
      flex: 2;
      position: relative;

      &--empty {
        width: 100%;
        height: auto;
        background: var(--banner-bg);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: var(--banner-svg);
          width: 20%;
          height: 20%;
        }
      }

      img {
        border-radius: 12px;
        width: 100%;
        height: auto;
      }
    }

    &__video {
      flex: 2;

      &--theatre-mode {
        margin-left: -24px;
        width: calc(100% + 2 * 24px);
      }
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 24px;

      &__title-with-indicator {
        display: block;
      }

      &__badge-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &__badges {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .details {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &__title {
          color: var(--text-primary);
          @include fontBody3;
          font-family: $PoppinsSemibold, sans-serif;
        }

        &__content {
          color: var(--content-color);
          @include fontBody2;
          font-family: $Poppins, sans-serif;

          &__price {
            display: flex;
            align-items: center;
            gap: 8px;
            @include fontBody1;
            font-family: $Poppins, sans-serif;
            color: var(--text-primary);

            &--number {
              font-family: $Poppins, sans-serif;
            }
          }
        }

        &__updates-info-box {
          @media (max-width: $screen-xl-max) {
            flex-direction: column;
            > div:first-child {
              width: 100%;
            }
          }
        }
      }
    }

    @media (max-width: $screen-lg-max) {
      flex-direction: column;
      gap: 36px;

      &__img-wrapper {
        img {
          width: 100%;
        }
      }
    }
  }

  .course-details {
    gap: 40px;
    display: flex;
    height: 116px;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    padding: 24px 124px;
    background-color: var(--info-box);

    @media (max-width: $screen-xxl-max) {
      gap: 8px;
      padding: 24px 60px;
    }

    @media (max-width: $screen-lg-max) {
      height: auto;
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: $screen-sm-max) {
      padding: 12px;
    }

    div {
      height: 100%;
    }

    h5,
    button {
      height: 48px;
      padding-left: 24px;
      margin-right: 60px;

      @media (max-width: $screen-lg-max) {
        padding: 12px;
      }
    }

    button {
      font-size: 16px;
    }
  }
}
