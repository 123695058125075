@import 'assets/scss/utils';

.wrapper {
  display: flex;
  min-height: 100%;

  .static-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &--light {
      background: url('../../../assets/images/login-static-bg-light.svg')
        no-repeat center center;
      background-size: cover;
    }

    &--dark {
      background: url('../../../assets/images/login-static-bg-dark.svg')
        no-repeat center center;
      background-size: cover;
    }

    .logo {
      position: absolute;
      top: 12px;
      left: 22px;
      cursor: pointer;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 60%;
      text-align: center;
    }

    @media (max-width: $screen-lg-max) {
      display: none;
    }
  }

  .dynamic-container {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--app-bg);
    padding: 24px;
    position: relative;

    @media (max-width: $screen-lg-max) {
      margin-top: 80px;
    }

    .c-btn--switch {
      position: absolute;
      top: 16px;
      right: 22px;
    }

    .header {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;

      @media (max-width: $screen-lg-max) {
        display: block;
      }
    }
  }
}
