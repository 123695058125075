@import 'assets/scss/fonts';
@import 'assets/scss/general';
@import 'assets/scss/variables';

.button {
  font-family: $PoppinsSemibold;
  border-radius: 8px;
  border: unset;
  cursor: pointer;
  transition:
    background-color 150ms ease-out,
    color 150ms ease-out;

  &:disabled {
    cursor: default;
  }

  &--md {
    padding: 12px;
    height: 48px;
  }
  &--sm {
    padding: 4px 8px;
    height: 32px;
  }

  &--icon {
    min-width: unset;

    &.button--md {
      width: 48px;
      height: 48px;
    }

    &.button--sm {
      width: 32px;
      height: 32px;
    }
  }

  &--no-padding {
    padding: 0;
    height: auto;
  }
}

// Button text wrapper
.text-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  &--center {
    justify-content: center;
  }
  &--left {
    justify-content: flex-start;
  }
}

// Min widths
.min-width {
  &--full {
    min-width: 100%;
  }
  &--lg {
    min-width: 250px;
  }
  &--md {
    min-width: 180px;
  }
  &--sm {
    min-width: 128px;
  }
}

// Button variants
.contrast {
  background: var(--btn-contrast-bg);
  color: $white20;

  svg {
    fill: $white20;
  }

  &:hover {
    background: var(--btn-contrast-bg--hover);
  }

  &:active {
    background: var(--btn-contrast-bg--active);
  }

  &:disabled {
    background: var(--btn-contrast-bg--disabled);
    color: var(--btn-color--disabled);
    svg {
      fill: var(--btn-color--disabled);
    }
  }
}

.neutral {
  background: var(--btn-neutral-bg);
  color: var(--text-primary);

  svg {
    fill: var(--text-primary);
  }

  &:hover:not([disabled]) {
    background: var(--btn-neutral-bg--hover);
  }

  &:active:not([disabled]) {
    background: var(--btn-neutral-bg--active);
  }

  &:disabled {
    color: var(--btn-color--disabled);
    svg {
      fill: var(--btn-color--disabled);
    }
  }
}

.outline {
  background: transparent;
  color: var(--text-primary);
  border: var(--btn-outline-border);

  svg {
    fill: var(--text-primary);
  }

  &:hover:not([disabled]) {
    background: rgba($purple50, 0.1);
  }

  &:active:not([disabled]) {
    background: rgba($purple50, 0.16);
  }

  &:disabled {
    color: var(--btn-color--disabled);
    border: var(--btn-outline-border--disabled);
    svg {
      fill: var(--btn-color--disabled);
    }
  }
}

.error {
  background: transparent;
  color: var(--text-primary);
  border: var(--btn-error-border);

  svg {
    fill: var(--text-primary);
  }

  &:hover:not([disabled]) {
    background: rgba($error40, 0.08);
  }

  &:active:not([disabled]) {
    background: rgba($error40, 0.12);
  }

  &:disabled {
    color: var(--btn-color--disabled);
    border: var(--btn-error-border--disabled);
    svg {
      fill: var(--btn-color--disabled);
    }
  }
}

.success {
  background: transparent;
  color: var(--text-primary);
  border: var(--btn-success-border);

  svg {
    fill: var(--text-primary);
  }

  &:hover:not([disabled]) {
    background: rgba($success40, 0.08);
  }

  &:active:not([disabled]) {
    background: rgba($success40, 0.12);
  }

  &:disabled {
    color: var(--btn-color--disabled);
    border: var(--btn-success-border--disabled);
    svg {
      fill: var(--btn-color--disabled);
    }
  }
}

.link-success {
  background: transparent;
  color: $success40;

  svg {
    fill: var(--text-primary);
  }

  &:hover:not([disabled]) {
    color: $success30;
  }

  &:active:not([disabled]) {
    background: rgba($success40, 0.12);
  }

  &:disabled {
    color: var(--btn-color--disabled);
    border: var(--btn-success-border--disabled);
    svg {
      fill: var(--btn-color--disabled);
    }
  }
}

.link-contrast {
  background: transparent;
  color: var(--btn-link-contrast-color);
  svg {
    fill: var(--btn-link-contrast-color);
  }

  &:hover {
    color: var(--btn-link-contrast-color--hover);
    svg {
      fill: var(--btn-link-contrast-color--hover);
    }
  }

  &:active {
    color: var(--btn-link-contrast-color--active);
    svg {
      fill: var(--btn-link-contrast-color--active);
    }
  }

  &:disabled {
    color: var(--link-color--disabled);
    svg {
      fill: var(--link-color--disabled);
    }
  }
}

.link-neutral {
  background: transparent;
  color: var(--text-primary);

  svg {
    fill: var(--text-primary);
  }

  &:hover {
    color: var(--btn-link-neutral-color--hover);
    svg {
      fill: var(--btn-link-neutral-color--hover);
    }
  }

  &:active {
    color: var(--btn-link-neutral-color--active);
    svg {
      fill: var(--btn-link-neutral-color--active);
    }
  }

  &:disabled {
    color: var(--link-color--disabled);
    svg {
      fill: var(--link-color--disabled);
    }
  }
}

.link-error {
  background: transparent;
  color: var(--btn-error);

  svg {
    fill: var(--btn-error);
  }

  &:hover {
    color: var(--btn-error--hover);
    svg {
      fill: var(--btn-error--hover);
    }
  }

  &:active {
    color: var(--btn-error--active);
    svg {
      fill: var(--btn-error--active);
    }
  }

  &:disabled {
    color: var(--btn-error--disabled);
    svg {
      fill: var(--btn-error--disabled);
    }
  }
}

[data-theme='light'] {
  --btn-contrast-bg: #{$purple50};
  --btn-contrast-bg--hover: #{$purple60};
  --btn-contrast-bg--active: #{$purple70};
  --btn-contrast-bg--disabled: #{$white20};

  --btn-neutral-bg: #{$white30};
  --btn-neutral-bg--hover: #{$white40};
  --btn-neutral-bg--active: #{$white50};

  --btn-outline-border: 1px solid #{$purple50};
  --btn-outline-border--disabled: 1px solid #{$white70};

  --btn-error: #{$error40};
  --btn-error--hover: #{$error30};
  --btn-error--active: #{$error50};
  --btn-error--disabled: #{$error20};
  --btn-error-border: 1px solid #{$error40};
  --btn-error-border--disabled: 1px solid #{$white70};

  --btn-success-border: 1px solid #{$success40};
  --btn-success-border--disabled: 1px solid #{$white70};

  --btn-link-contrast-color: #{$purple50};
  --btn-link-contrast-color--hover: #{$purple70};
  --btn-link-contrast-color--active: #{$purple90};

  --btn-link-neutral-color--hover: #{$black30};
  --btn-link-neutral-color--active: #{$white90};

  // Common
  --btn-color--disabled: #{$white60};
  --link-color--disabled: #{$white40};
}

[data-theme='dark'] {
  --btn-contrast-bg: #{$purple60};
  --btn-contrast-bg--hover: #{$purple50};
  --btn-contrast-bg--active: #{$purple40};
  --btn-contrast-bg--disabled: #{$black80};

  --btn-neutral-bg: #{$black80};
  --btn-neutral-bg--hover: #{$black60};
  --btn-neutral-bg--active: #{$black40};

  --btn-outline-border: 1px solid #{$purple70};
  --btn-outline-border--disabled: 1px solid #{$black50};

  --btn-error: #{$error40};
  --btn-error--hover: #{$error30};
  --btn-error--active: #{$error50};
  --btn-error--disabled: #{$error20};
  --btn-error-border: 1px solid #{$error40};
  --btn-error-border--disabled: 1px solid #{$black50};

  --btn-success-border: 1px solid #{$success40};
  --btn-success-border--disabled: 1px solid #{$black50};

  --btn-link-contrast-color: #{$purple40};
  --btn-link-contrast-color--hover: #{$purple30};
  --btn-link-contrast-color--active: #{$purple20};

  --btn-link-neutral-color--hover: #{$white80};
  --btn-link-neutral-color--active: #{$black10};

  // Common
  --btn-color--disabled: #{$black40};
  --link-color--disabled: #{$black40};
}
