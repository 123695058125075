@import 'assets/scss/variables';
@import 'assets/scss/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--app-bg);
  padding-top: 80px;
  transition: background-color 150ms ease-out;

  .container {
    width: $screen-xxl-max;
    padding: 24px;
    height: fit-content;

    &--no-padding {
      padding: 0;
    }

    &--full-width {
      width: 100%;
    }

    @media (max-width: $screen-xxl-max) {
      width: 100%;
    }
  }
}
