@import 'assets/scss/utils';
@import 'assets/scss/general';

.review {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
    max-width: 656px;
  }

  &__summary {
    width: 100%;
  }

  &__btns-container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    > button {
      flex: 1;
    }
  }
}
