@import 'assets/scss/fonts';
@import 'assets/scss/general';
@import 'assets/scss/variables';
@import 'assets/scss/mixins';

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  word-break: break-word;
  hyphens: auto;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

html {
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-bg);
    border-radius: 5px;
  }
}

body {
  font-family: $Poppins;
  font-style: normal;
  font-stretch: normal;
  cursor: default;
  @include fontBody2;
  overflow-y: auto;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
  --wcm-z-index: 100 !important;
}

img {
  margin: 0;
}

button {
  border-style: none;
  line-height: 1em;
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  @include fontBody2;
}

.react-datepicker {
  border-radius: 0;
}
.react-datepicker__day {
  border-radius: 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background: black;
}
.react-datepicker__day:hover {
  border-radius: 0;
}

div {
  word-break: auto-phrase;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  text-decoration: none;
  padding: 0;
  margin: 0;
  font-weight: normal;
  word-break: auto-phrase;
}

a {
  background-color: transparent;
  text-decoration: underline;
  color: inherit;
  outline: none;
}

b,
strong {
  font-weight: bolder;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box !important;
}

input {
  padding: 0;
}

textarea {
  resize: none !important;
}

textarea:focus,
input:focus {
  outline: none;
}
