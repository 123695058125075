@import 'assets/scss/fonts';
@import 'assets/scss/utils';

// Form layout
.form-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 650px;

  &--popup {
    width: 100%;
    gap: 24px;
  }

  &--sm {
    width: 100%;
    max-width: 432px;
  }

  .btns-wrapper {
    display: flex;
    gap: 8px;

    button {
      flex: 1;
    }

    &--single {
      button {
        flex: 0.5;
      }
    }

    &--single--end {
      justify-content: flex-end;
      button {
        flex: 0.5;
      }
    }

    @media (max-width: $screen-sm-max) {
      flex-direction: column-reverse;
    }
  }

  @media (max-width: $screen-lg-max) {
    width: 100%;
  }
}

// Price Box
.price-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  background: var(--secondary-bg);

  .chevron {
    fill: var(--text-primary);
    &--open {
      rotate: 0deg;
    }
    &--closed {
      rotate: 180deg;
    }
  }

  &--bordered {
    border: 1px solid var(--border-color);
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: center;
    color: var(--text-primary);
    font-family: $PoppinsSemibold;
    @include fontBody3;
  }

  &__value {
    color: var(--text-primary);
    font-family: $Poppins;
    @include fontBody2;
  }
}

// Form step `Summary`
.summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background: var(--secondary-bg);

  .row {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__title {
      color: var(--text-primary);
      font-family: $PoppinsSemibold;
      @include fontBody3;

      &--error {
        color: $error40;
        span {
          display: flex;
          align-items: flex-start;
          gap: 4px;

          svg {
            fill: $error40;
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    &__desc {
      color: var(--content-color);
      font-family: $Poppins;
      @include fontBody2;

      &--flex {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    &__img-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex: 1;
    }

    &__img-wrapper--flex {
      flex-direction: row;
      align-items: center;
      gap: 16px;

      @media (max-width: $screen-sm-max) {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 4px;
      }
    }

    &__img {
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 128px;
      border-radius: 4px;

      video {
        border-radius: 4px;
      }
    }

    &__img--square {
      width: 192px;
      height: 192px;
      border-radius: 12px;
      object-fit: cover;
      margin-top: 4px;
    }

    &__img-title {
      color: var(--text-primary);
      font-family: $PoppinsSemibold;
      @include fontBody2;
    }

    &--flex {
      flex-direction: row;
      align-items: start;
      gap: 16px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @media (max-width: $screen-sm-max) {
        grid-template-columns: repeat(1, 1fr);

        .row__img {
          width: 128px;
        }
      }
    }
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  // Safari
  height: fit-content;

  &--four {
    grid-template-columns: repeat(4, 1fr);
  }

  &--two {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $screen-xl-max) {
    grid-template-columns: repeat(3, 1fr);

    &--two {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: $screen-lg-max) {
    grid-template-columns: repeat(2, 1fr);

    &--two {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media (max-width: $screen-md-max) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.btns-row {
  display: flex;
  gap: 8px;

  &__btn {
    width: 100%;
    &--success {
      color: $success40;
      svg {
        fill: $success40;
      }
    }
    &--error {
      color: $error40;
      svg {
        fill: $error40;
      }
    }
  }

  > div {
    width: 100%;
  }

  @media (max-width: $screen-sm-max) {
    width: 100%;
    flex-direction: column;
  }
}

.notification-circle {
  display: inline-block;
  flex-shrink: 0;
  background-color: $warning40;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.safari-support-overflow {
  overflow: hidden;
}

.mobile-menu-backdrop {
  position: fixed;
  top: 80px;
  left: 0;
  background: var(--overlay);
  z-index: 9;
  width: 100vw;
  height: 100%;
}
