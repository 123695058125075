@import '_variables.scss';

@mixin fontH1 {
  font-size: $h1;
  line-height: $lhH1;
}
@mixin fontH2 {
  font-size: $h2;
  line-height: $lhH2;
}
@mixin fontH3 {
  font-size: $h3;
  line-height: $lhH3;
}
@mixin fontH4 {
  font-size: $h4;
  line-height: $lhH4;
}
@mixin fontH5 {
  font-size: $h5;
  line-height: $lhH5;
}
@mixin fontBody1 {
  font-size: $body1;
  line-height: $lhBody1;
}
@mixin fontBody2 {
  font-size: $body2;
  line-height: $lhBody2;
}
@mixin fontBody3 {
  font-size: $body3;
  line-height: $lhBody3;
}
@mixin fontBody4 {
  font-size: $body4;
  line-height: $lhBody4;
}
@mixin disabledTextColor {
  color: var(--disabled-color) !important;
  fill: var(--disabled-color) !important;
  svg {
    fill: var(--disabled-color) !important;
  }
}
