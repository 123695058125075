@import 'assets/scss/utils';
@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.island {
  position: sticky;
  left: 0;
  bottom: 0;
  background-color: var(--app-bg);
  display: flex;
  justify-content: center;
  padding: 40px 0px;
  margin-top: 40px;
  width: 100%;
  z-index: 11;

  @media (max-width: $screen-lg-max) {
    position: sticky;
    padding: 40px 24px;
    width: calc(100% + 48px);
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 650px;

    @media (max-width: $screen-sm-max) {
      flex-direction: column-reverse;
    }

    &__btns-container {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;

      @media (max-width: $screen-sm-max) {
        flex-direction: column-reverse;
      }

      button {
        width: 100%;
      }
    }
  }
}
