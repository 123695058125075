@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.step-field {
  width: 100%;
  width: 493px;
  padding: 6px;
  display: flex;
  justify-content: space-between;

  @media (max-width: $screen-xl-max) {
    width: auto;
  }

  svg {
    width: 32px;
    height: 32px;
  }

  &__name {
    gap: 8px;
    display: flex;
    cursor: pointer;
    align-items: center;

    h5 {
      padding-top: 8px;
    }
  }
}

.guide-field {
  display: flex;
  margin-top: 8px;

  .divider {
    padding-left: 32px;
    div {
      height: 100%;
    }
  }

  h5 {
    white-space: pre-line;
    padding-left: 32px;
  }
}
