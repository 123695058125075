@import "assets/scss/fonts";
@import "assets/scss/utils";

.accordion {
  &__el {
    border-bottom: 1px solid var(--border-color);
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    cursor: pointer;
  }

  &__title {
    @include fontBody2;
    font-family: $PoppinsBold, sans-serif;
    color: var(--text-primary);
  }

  &__arrow {
    fill: var(--content-color);
    transition: transform 150ms ease-out;

    &--down {
      transform: rotate(180deg);
    }
  }

  &__content {
    display: none;
    margin-bottom: 16px;
    color: var(--text-primary);

    &--open {
      display: block;
    }
  }
}
