@import 'assets/scss/variables';
@import 'assets/scss/utils';

.card {
  display: flex;
  cursor: pointer;
  position: relative;
  flex-direction: column;

  .context-wrapper {
    top: 15px;
    z-index: 1;
    right: 15px;
    position: absolute;

    svg {
      width: 20px;
      height: 20px;
    }

    .buttons-wrapper {
      width: 140px;
      display: flex;
      flex-direction: column;
    }
  }

  &--clickable {
    cursor: pointer;
    transition:
      box-shadow 150ms ease-out,
      border 150ms ease-out;
  }

  &__img-wrapper {
    width: 100%;
    overflow: hidden;
    max-height: 581px;
    position: relative;
    aspect-ratio: 1.33 / 1;
    border-radius: 12px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    gap: 8px;
    z-index: 1;
    display: flex;
    padding: 24px;
    margin-top: -182px;
    border-radius: 12px;
    flex-direction: column;

    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    );
  }

  &__title {
    color: $white10;
  }

  &__author {
    gap: 8px;
    display: flex;
    margin-top: 24px;
    align-items: center;

    svg {
      padding: 2px;
      border-radius: 20px;
      fill: var(--banner-svg);
      border: 1px solid var(--banner-svg);
    }
  }
}
