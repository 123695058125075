@import 'assets/scss/variables';
@import 'assets/scss/general';
@import 'assets/scss/utils';

.card {
  gap: 8px;
  padding: 8px;
  display: flex;
  min-height: 382px;
  position: relative;
  border-radius: 16px;
  border: 1px solid var(--border-color);

  .link-contrast {
    background: transparent;
    color: var(--btn-link-contrast-color);
  }

  &__filtered {
    min-height: 302px;
  }

  &__thumb {
    width: 100%;
    display: flex;
    min-height: 192px;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    padding: 8px;

    &__details {
      gap: 8px;
      display: flex;
      flex-direction: column;
      color: var(--text-primary);
    }

    &__price {
      display: flex;
      align-items: center;
      gap: 8px;
      @include fontBody1;
      font-family: $Poppins, sans-serif;
      color: var(--text-primary);
      margin-top: auto;

      &--number {
        font-family: $PoppinsBold, sans-serif;
      }
    }
  }

  @media (max-width: $screen-md-max) {
    flex-direction: column;
  }
}
