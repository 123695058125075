@import 'assets/scss/fonts';
@import 'assets/scss/utils';

.header {
  color: var(--text-primary);
  font-family: 'Poppins Bold', sans-serif;
  font-size: $body1;
}

.wrapper {
  width: 100%;
  display: flex;
  max-width: 880px;
  position: relative;

  .line {
    height: 1px;
    display: block;
    width: 100%;
    position: absolute;
    top: 16px;
    background-color: var(--border-color);
    transition: background-color 150ms ease-out;
  }

  .step {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 12px;

    &__number {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      background-color: var(--step);
      color: var(--text-primary);
      font-family: $PoppinsSemibold, sans-serif;
      font-size: $body2;
      transition: background-color 150ms ease-out;

      &__wrapper {
        padding: 0 8px;
        background-color: var(--app-bg);
        z-index: 1;
        transition: background-color 150ms ease-out;
      }

      svg {
        fill: var(--text-primary-inverted);
      }
    }

    &__title {
      font-family: $Poppins, sans-serif;
      font-size: $body3;
      color: var(--content-color);
    }

    &--clickable {
      .step__number {
        cursor: pointer;
      }
    }

    &--completed {
      .step__number {
        background-color: var(--step-completed);
        cursor: pointer;
      }
    }

    &--error {
      .step__number {
        background-color: $error40;
        cursor: pointer;
      }
    }

    &--active {
      .step__number {
        color: $white10;
        background-color: $purple50;
        cursor: pointer;
      }
    }

    &--disabled {
      .step__number {
        color: var(--text-primary-inactive);
        background-color: var(--step-disabled);
      }

      .step__title {
        color: var(--text-primary-inactive);
      }
    }
  }
}
