.context-menu {
  position: relative;
  &__dropdown {
    width: max-content;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: var(--main-shadow);
    padding: 8px;
    background-color: var(--app-bg);
    z-index: 1;
    button {
      > div {
        justify-content: flex-start;
      }
    }
  }
}
