@import 'assets/scss/variables';
@import 'assets/scss/utils';

.highlighted {
  gap: 48px;
  padding: 48px;
  display: grid;
  position: relative;
  border-radius: 16px;
  background-color: var(--info-box);
  color: var(--text-primary);
  cursor: pointer;

  &--sm {
    grid-template-columns: 191px 1fr;
    padding: 16px 16px 16px 44px;
  }

  &--lg {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: $screen-lg-max) {
    gap: 24px;
    height: 460px;
    padding: 24px;
    border-radius: 0px;
    grid-template-columns: auto;
  }

  &__img-col {
    display: flex;
    justify-content: center;
    align-items: center;

    .dnd-icon {
      position: absolute;
      fill: var(--text-primary);
      top: calc(50% - 12px);
      left: 12px;
      z-index: 1;
      cursor: grab;
    }

    &__fav-btn {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    &__badge {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    &__thumbnail {
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      border-radius: 8px;

      &--sm {
        height: 104px;
      }

      &--lg {
        height: 360px;
      }

      @media (max-width: $screen-lg-max) {
        height: 214px;
      }
    }
  }
  &__info-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    h3,
    h5 {
      margin-bottom: 16px;
    }

    &__desc {
      margin-bottom: 24px;
    }

    &__rating {
      margin-bottom: 24px;
    }

    &__btns-container {
      display: flex;

      .remove {
        margin-left: auto;
      }

      .view {
        justify-content: center;
        width: 50%;

        @media (max-width: $screen-lg-max) {
          width: 100%;
        }
      }

      button {
        @media (max-width: $screen-lg-max) {
          width: 100%;
        }
      }
    }
  }
}
