@import 'assets/scss/variables';
@import 'assets/scss/utils';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .notification {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -7px;
    right: -7px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: $purple50;
    color: white;
    z-index: 1;
  }
}

.c-avatar {
  flex-shrink: 0;
  border-radius: 12px;
  object-fit: cover;
  border: 4px solid var(--app-bg);
  transition: border 150ms ease-out;

  &--default {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--banner-bg);
    transition: background-color 150ms ease-out;

    svg {
      fill: var(--banner-svg);
      width: 55%;
      height: 55%;
    }
  }

  &--borderless {
    border: unset;
  }

  &--xs {
    width: 40px;
    height: 40px;
  }

  &--sm {
    width: 48px;
    height: 48px;
  }

  &--md {
    width: 56px;
    height: 56px;
  }

  &--lg {
    width: 96px;
    height: 96px;
  }

  &--xl {
    width: 192px;
    height: 192px;
  }
}
