@import 'assets/scss/variables';
@import 'assets/scss/utils';

.toast-wrapper {
  .toast-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    background: var(--toast-bg);
    z-index: 100;

    .msg-wrapper {
      font-family: $Poppins;
      display: flex;
      gap: 4px;
    }

    .icon-container {
      display: flex;
      align-items: flex-start;
      svg {
        fill: var(--text-primary);
      }
      &--success {
        svg {
          fill: $success40;
        }
      }

      &--warning {
        svg {
          fill: $warning50;
        }
      }

      &--error {
        svg {
          fill: $error40;
        }
      }
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: 14px;
      color: var(--text-primary);
      @include fontBody2;

      .title {
        font-family: $PoppinsBold;
      }

      .subtitle {
        display: flex;
        gap: 4px;
        button {
          text-decoration: underline;
        }
      }

      p {
        max-width: 320px;
        color: var(--text-primary);
      }
      a {
        color: var(--text-primary);
      }
    }

    > button {
      padding: 0;
      height: unset;
      width: unset;
    }
  }
}

[data-theme='light'] {
  --toast-bg: #{$white30};
}

[data-theme='dark'] {
  --toast-bg: #{$black80};
}
